import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Text } from '../../components';
import authController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import Utilities from '../../utilities';
import { Header } from '../../containers';
import { style } from './style';
import { Analytics, PageHit } from 'expo-analytics';
const analytics = new Analytics('G-YCC5QPC8BJ');
import { init, track } from '@amplitude/analytics-react-native';

class Feedback_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: null,
      notes: null
    }

    this.updateInput = this.updateInput.bind(this);
    this.sendFeedback = this.sendFeedback.bind(this);
  }

  componentDidMount() {
    track('Feedback Page');
    analytics.hit(new PageHit('Feedback'))
      .then(() => {})
      .catch(e => console.log("analytics error", e.message));
  }

  updateInput(value, name) {

    let login_valid = this.state.email && this.state.notes;

    console.log('state', this.state);

    if(name === 'email') {
      let valid = Utilities.validateEmail(value);

      login_valid = login_valid && value && valid;
    }

    if(name === 'notes') {
      login_valid = login_valid && value;
    }

    this.setState({
      login_valid,
      [name]: value
    })
  }

  async sendFeedback() {
    this.setState({loading: true}, async ()=>{
      let { email, notes } = this.state;
      let data = { email, notes };

      let response = await UserController.sendFeedback(data);

      if(response.success) {
        this.setState({
          loading: false,
          feedback_sent: true
        })
      } else {
        this.setState({
          loading: false,
          message: response.error
        });
      }
    })
  }

  render() {
    let { message, login_valid, email, loading, notes, feedback_sent } = this.state;

    return (
      <View style={style.page_container}>
        <View style={style.lr_container}>
          <Text style={style.login_text}>Feedback</Text>
          {!feedback_sent ?
              <View>
                <Text style={style.intro}>We welcome any and all feedback that can help improve our club for our members!</Text>
                <View style={style.lr_tab}>
                  <View style={style.line_breaker}></View>
                  <Input style={style.input_box} type="email" value={email}  placeholder="Your email so we can respond." onChangeText={event => { this.updateInput(event, 'email') }} />

                  <Input style={style.input_box_multi} multiline={true} type="text" value={notes}  placeholder="Your feedback." onChangeText={event => { this.updateInput(event, 'notes') }} />

                  <View style={style.line_breaker}></View>

                  <Button title='Send'
                    loading={loading}
                    style={style.login_button}
                    style_inactive={style.inactive_login_button}
                    inactive={!login_valid}
                    onPress={this.sendFeedback} />
                  <Text style={style.error_text}>{message}</Text>
              </View>
            </View>: <View>
            <Text style={style.intro}>
              Thank you for your feedback. We'll be in touch with any necessary follow-up.
            </Text>
              </View>}

          
        </View>
      </View>
    );
  }
}

function GeneralFeedback(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();
    return <Feedback_Base {...props} navigate={navigate} />
  } else {
    return <Feedback_Base {...props} navigate={props.navigation.push} />
  }
}

export default GeneralFeedback;
