import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity, Image, Animated, Dimensions } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Text } from '../../components';
import authController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import EventController from '../../controllers/eventController';
import Utilities from '../../utilities';
import { FAQ } from '../../containers';
import { style } from './style';
import { Analytics, PageHit } from 'expo-analytics';
const analytics = new Analytics('G-YCC5QPC8BJ');
import { init, track } from '@amplitude/analytics-react-native';

import { MaterialIcons } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';

class Membership_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mounted: true
    }

    this.explore = React.createRef();

    this.goToPricing = this.goToPricing.bind(this);

  }

  async componentDidMount() {
    track('Membership Page');
    analytics.hit(new PageHit('Pricing'))
    .then(() => {})
    .catch(e => console.log("analytics error", e.message));
  }

  goTo(link) {
    window.open(link)
  }

  async goToPricing() {
    let token = await localStorage.getItem('token');
    let isLoggedIn = !!token;

    if(isLoggedIn) {
      this.props.navigate('/dashboard/subscription')
    } else {
      this.props.navigate('/register')
    }
  }

  render() {

    let width = Dimensions.get('window').width;
    let height = Dimensions.get('window').height;

    let is_mobile = width < 500;

    return (<View style={style.explainer_section}>
      <View style={{backgroundColor: '#f7f8fc'}}>
      <View style={{flexDirection: 'column'}}>
            <View ref={node=>this.dining=node} style={{flexDirection: 'row', borderColor: '#999999', borderBottomWidth: 1}}><Text style={style.section_title}>Membership</Text></View>
      
              <View style={{flexDirection: is_mobile ? 'column' : 'row'}}>
                <View style={{ width: is_mobile ? '90%' : 500, padding: is_mobile ? 'unset' : 50, paddingBottom: 50, margin: is_mobile ? 'auto' : 'unset'}}>

                    <Text style={style.dining_sub_title}>
                      Social Dining Club around Your Schedule
                    </Text>
                    <Text style={style.dining_text}>
                     Find the membership that fits your needs. DiningSocial is a new way of making friends and connections in a cozy groups of 3-6 people at a time at local restaurants. Choose a plan depending on how many times you plan on joining us a month. The Membership plan becomes more affordable when you plan on joining us more than once a month.
                    </Text>
                    <View style={{marginTop: 40}}>
                      <TouchableOpacity onPress={()=>{this.explore.scrollIntoView()}} ><Text style={{fontFamily: 'Comfortaa', fontSize: 20, color: 'black', borderWidth: 1, borderColor: '#999999', paddingVertical: 10, paddingHorizontal: 20, width: 150, textAlign: 'center'}}>Explore</Text></TouchableOpacity>
                      
                    </View>
                </View>
                <View style={[style.wrap_dining_image, {padding: is_mobile ? 0 : 50, borderLeftWidth: is_mobile ? 0 : 1, paddingBottom: 50}]}>
                  <View style={{position: 'relative'}}>
                        <Image source={{ uri: 'https://diningsocial-assets.s3.amazonaws.com/social.png' }} style={[style.our_dining_image, {width: is_mobile ? '100%' : 600, height: is_mobile ? 400 : 500
                        }]} resizeMode='cover' />
                  </View>
                </View>
              </View>
   
          </View>
          <View style={{flexDirection: 'column'}}>
            <View ref={node=>this.explore=node} style={{flexDirection: 'row', borderColor: '#999999', borderBottomWidth: 1, borderTopWidth: 1}}><Text style={[style.section_title, {fontSize: 40}]}>Types</Text></View>
            <View  style={{flexDirection: is_mobile ? 'column' : 'row', borderBottomWidth: 1, borderColor: '#999999'}}>
              <View style={{flexDirection: 'column', width: is_mobile ? '100%' : 615, borderRightWidth: 1, borderColor: '#999999'}}>
                <View style={style.wrap_dining_image}>
                  <View style={{}}>
                    <Text style={[style.dining_sub_title, {height: 50}]}>
                      Member
                    </Text>
                    <View style={{height: is_mobile ? 'unset' : 250, marginBottom: is_mobile ? 30 : 'unset'}}>
                      <Text style={[style.dining_text]}>
                        Go out in groups of 3 to 6 member at a time for dinner or brunch, any day of the week, unlimited number of times a month. Our dining get-togethers are scheduled around our subscribing members' availability and take place at participating local restaurants. Our members are also eligeable for the points system which allows them to get membership free of charge. 
                      </Text>
                    </View>
                  </View>
                  <Image source={{ uri: 'https://diningsocial-assets.s3.amazonaws.com/members.png' }} style={[style.our_dining_image, {width: is_mobile ? '100%' : 495
                        }]} resizeMode='cover' />
                </View>
              </View>
              <View style={{flexDirection: 'column', width: is_mobile ? '100%' : 615}}>
                <View style={style.wrap_dining_image}>
                  <View>
                    <Text style={[style.dining_sub_title, {height: 50, marginBottom: 0}]}>
                      Visitor
                    </Text>
                    <View style={{height: is_mobile ? 'unset' : 250, justifyContent: 'center', marginBottom: 30}}>
                      <Text style={[style.dining_text]}>
                        Our visitors pay a one-time fee for one dining event at a time. Visitors do not get the pleasure of having our get-togethers scheduled around their availability. They also are not eligeable to participate in our points program to receive free membership months.
                      </Text>
                    </View>
                  </View>
                  <Image style={[style.our_dining_image, {width: is_mobile ? '100%' : 'unset'}]} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/seven.jpg'}} />
                  
                </View>
              </View>
            </View>
          </View>
          <View ref={node=>this.explore=node} style={{flexDirection: 'row', borderColor: '#999999', borderBottomWidth: 1}}><Text style={[style.section_title, {fontSize: 40}]}>Pricing</Text></View>
          {is_mobile ? <View style={{height: 100, width: '100%', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
            <FontAwesome name="arrows-h" size={30} />    
          </View> : null}
          <View style={{flexDirection: 'row', margin: 'auto', marginBottom: 100, marginTop: 20, width: is_mobile ? '100%' : 'unset', overflow: is_mobile ? 'scroll' : 'unset', paddingLeft: is_mobile ? 20 : 'unset'}}>
           <View style={{flexDirection: 'column', width: is_mobile ? 180 : 280}}>
            <View style={{flexDirection: 'row', height: 101}}></View>
            <View style={{flexDirection: 'row', height: is_mobile ? 106 : 85, borderWidth: 1, borderRightWidth: 0, borderLeftWidth: 0, borderColor: '#a19f9f'}}>
               <Text style={style.membership_tab}>Dinner/Brunch Around Your Schedule</Text>
            </View>
            <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center'}}>
              <Text style={style.membership_tab}>Free Subscription Eligibility</Text>
            </View>
            <View style={{flexDirection: 'row', borderColor: '#a19f9f', height: is_mobile ? 105 : 84, borderBottomWidth: 1, alignItems: 'center'}}>
              <Text style={style.membership_tab}>Subscription</Text>
            </View>
            <View style={{flexDirection: 'row', borderColor: '#a19f9f', height: is_mobile ? 105 : 84, borderBottomWidth: 1, alignItems: 'center'}}>
              <Text style={style.membership_tab}>Dinner/Brunch Attendance Fee</Text>
            </View>
            <View style={{flexDirection: 'row', borderColor: '#a19f9f', height: is_mobile ? 105 : 84, borderBottomWidth: 1, alignItems: 'center'}}>
              <Text style={style.membership_tab}>Bigger Dining Event Fee</Text>
            </View>
           </View>
           <View style={{flexDirection: 'column', borderWidth: 1, borderTopWidth: 2, borderLeftWidth: 2, width: 340, borderColor: '#a19f9f'}}>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, height: 100, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}><Text style={{fontFamily: 'Ysabeau', fontSize: 40}}>Member</Text></View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <FontAwesome name="check" size={30} />
              </View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <FontAwesome name="check" size={30} />
              </View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{fontFamily: 'Comfortaa', fontSize: 20 }}>$30 / mo</Text>
              </View>
              <View style={{flexDirection: 'column', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{fontFamily: 'Comfortaa', fontSize: 20 }}>$0 / dinner or brunch</Text>
                <Text style={{fontFamily: 'Ysabeau', fontSize: 16, marginTop: 0 }}>(unlimited times per month)</Text>
              </View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{fontFamily: 'Comfortaa', fontSize: 20}}>$5 / event</Text>
              </View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <TouchableOpacity onPress={()=>{track('Membership Register Button 1'); window.location.replace('/register')}}><Text style={{fontFamily: 'Comfortaa', fontSize: 20, color: 'white', backgroundColor: 'black', paddingVertical: 20, paddingHorizontal: 40 }}>Register for Free</Text></TouchableOpacity>
              </View>
           </View>
           <View style={{flexDirection: 'column', borderColor: '#a19f9f', borderWidth: 1, width: 340, borderTopWidth: 2, borderRightWidth: 2}}>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', height: 100, borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}><Text style={{fontFamily: 'Ysabeau', fontSize: 40}}>Visitor</Text></View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <FontAwesome name="check" size={30} />
              </View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <FontAwesome name="times" size={30} color="#e32652" />
              </View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{fontFamily: 'Comfortaa', fontSize: 20 }}>$0 / mo</Text>
              </View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{fontFamily: 'Comfortaa', fontSize: 20 }}>$12 / dinner or brunch</Text>
              </View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{fontFamily: 'Comfortaa', fontSize: 20}}>$25 / event</Text>
              </View>
              <View style={{flexDirection: 'row', height: is_mobile ? 105 : 84, borderColor: '#a19f9f', borderBottomWidth: 1, alignItems: 'center', justifyContent: 'center'}}>
                <TouchableOpacity onPress={()=>{track('Membership Register Button 2'); window.location.replace('/register')}}><Text style={{fontFamily: 'Comfortaa', fontSize: 20, color: 'white', backgroundColor: 'black', paddingVertical: 20, paddingHorizontal: 40 }}>Register for Free</Text></TouchableOpacity>
              </View>
           </View>
          </View>

          <View style={{position: 'relative', justifyContent: 'center', alignItems: 'center', width: '80%', backgroundColor: '#1e1c1c', height: 300, textAlign: 'center', margin: 'auto', borderTopWidth: 1, borderTopColor: '#241a1a', marginBottom: 200}} >
              <Text style={{color: 'white', fontFamily: 'Ysabeau', fontSize: 40, marginBottom: 30}}>Still Have Questions?</Text>
              <TouchableOpacity onPress={this.props.toggleContact} ><Text style={[style.three_buttons_button, {width: 250}]}>We're waiting to hear from you!</Text></TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }
}

function Membership(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();

    return <Membership_Base {...props} navigate={navigate} />
  } else {
    return <Membership_Base {...props} navigate={props.navigation.push} />
  }
}

export default Membership;
