import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity, Image, Dimensions } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Text, Input, Button } from '../../components';
import Utilities from '../../utilities';
import { style } from './style';
import { Analytics, PageHit } from 'expo-analytics';
const analytics = new Analytics('G-YCC5QPC8BJ');
import { init, track } from '@amplitude/analytics-react-native';

import { global_style } from '../../../global_style';
import Icon from 'react-native-vector-icons/FontAwesome';
import AuthController from '../../controllers/authController';
const width = Dimensions.get('window').width;

class HowWorks_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }

    this.updateEmail = this.updateEmail.bind(this);
    this.addNewsletter = this.addNewsletter.bind(this);
    this.addCoffee = this.addCoffee.bind(this);
    this.emailRegister = this.emailRegister.bind(this);
  }

  async componentDidMount() {
    track('HowItWorks Page');
    analytics.hit(new PageHit('Pricing'))
    .then(() => {})
    .catch(e => console.log("analytics error", e.message));
  }

  goTo(link) {
    window.open(link)
  }

  async goToPricing() {
    let token = await localStorage.getItem('token');
    let isLoggedIn = !!token;

    if(isLoggedIn) {
      this.props.navigate('/dashboard/subscription')
    } else {
      this.props.navigate('/register')
    }
  }

  updateEmail(email, name) {

    let email_valid = !!this.state[`email_${name}`];

    let valid = Utilities.validateEmail(email);

    email_valid = email_valid && !!email && valid;

    this.setState({
      [`email_valid_${name}`]: email_valid,
      [`email_${name}`]: email
    })
  }

  async addNewsletter() {

    this.setState({newsletter_loading: true}, async ()=>{
      let email = this.state.email_newsletter;

      await AuthController.addNewsletter({email});

      this.setState({
        newsletter_loading: false,
        email_newsletter: null,
        email_valid_newsletter: null,
        newsletter_added: true
      });
    })
  }

  async addCoffee() {
    let email = this.state.email_newsletter;

    email = encodeURIComponent(email);

    await AuthController.addNewsletter({email, coffee: true});

    this.props.navigate(`/register?email=${email}&coffee=true`);
  }

  async emailRegister() {
    let email = this.state.email_register;

    email = encodeURIComponent(email);

    this.props.navigate(`/register?email=${email}`);
  }

  render() {

    let { email_newsletter, email_valid_newsletter, email_valid_register, email_register, newsletter_loading, newsletter_added } = this.state;

    let is_mobile = width < 500

    let guilded_dining = "Guided Dining"

    return (
      <View style={style.explainer_section}>
      <View style={style.restaurants_wrapper}>
        <View style={style.restaurant_wrapper}>          
          <View style={style.whole_wrapper}>
            <View style={{flexDirection: is_mobile ? 'column' : 'row', marginTop: 40, padding: 40, paddingBottom: is_mobile ? 10 : 40}}>
              <View style={{width: is_mobile ? 'calc(100vw - 80px)' : 600, marginLeft: is_mobile ? 0 : 100}}>
                <Text style={{textAlign: is_mobile ? 'center' : 'left', fontFamily: 'Ysabeau', fontSize: is_mobile ? 60 : 70, lineHeight: is_mobile ? 50 : 70, marginBottom: is_mobile ? 30 : 10}}>Socialize over Coffee or Dinner.</Text>
                <View style={{flexDirection: 'row', marginLeft: is_mobile ? 0 : 50, marginBottom: is_mobile ? 0 : 0}}>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 26, paddingLeft: is_mobile ? 0 : 50 , paddingRight: is_mobile ? 0 : 30}}>We're a social network that likes to sit down and get to know each-other in a cozy setting. We don't believe in large, loud groups or cookie-cutter ways of matching up personalities. Friendship & connection is much more than a few checked boxes. The only two brackets we pay attention to are 1. Age and 2. Gender.</Text>
                </View>
              </View>
              {is_mobile ? null :
              <Image style={{width: is_mobile ? 'calc(100vw - 20px)' : 400, height: 340, borderRadius: 5, float: 'right'}} resizeMode='cover' source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/seven.jpg'}} />}
            </View>
            <View style={{flexDirection: 'column', padding: is_mobile ? 40 : 50, alignItems: 'center'}}>
              
              {is_mobile ? 
              <View style={{flexDirection: is_mobile ? 'column' : 'row'}}>
                <View style={{marginTop: is_mobile ? 0 :50}}>
                  {is_mobile ?
                  <View style={style.testimonial_first_tile}>
                    <View style={style.testimonial_border}>
                      <Text style={style.testimonial_summary_text}>
                      <View style={{textAlign: 'center', alignItems: 'center', marginLeft: 20, float: 'right'}}>
                        <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/9aa1513b-30fe-407f-9d23-3c7c083bd562-1706484409682'}} />
                        <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                          Sachin
                        </Text>
                      </View>"I get to sit down in a small group and actually get to know people without the interruptions of a larger and louder event."
                      </Text>
                      
                    </View>
                  </View> : <View style={[style.testimonial_first_tile, {marginRight: 100}]}>
                    <View style={style.testimonial_border}>
                      <Text style={style.testimonial_summary_text}>"I get to sit down in a small group and actually get to know people without the interruptions of a larger and louder event."</Text>
                      <View style={{textAlign: 'center', alignItems: 'center', marginLeft: 20}}>
                        <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/9aa1513b-30fe-407f-9d23-3c7c083bd562-1706484409682'}} />
                        <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                          Sachin
                        </Text>
                      </View>
                    </View>
                  </View>}
                </View>
                
                <View style={{width: is_mobile ?  'calc(100vw - 40px)' : 400, padding: is_mobile ? 30 : 0}}>
                  <Text style={{fontFamily: 'Comfortaa', fontSize: 40, marginBottom: 10}}>RSVP for any open spot.</Text>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 20}}>
                    If you're the first to RSVP, you get to choose the demographic of people you'd like to meet and the spot you'd like to meet them. For dinner, we make a reservation for you. For coffee, instead of making a reservation, we choose cafes where everyone in the group can order on their own. The final confirmation on the restaurant will come the day before and, 30 minutes before, a GroupMe chat will open so that you can find your group.</Text>
                </View>
              </View> :
              <View style={{flexDirection: is_mobile ? 'column' : 'row'}}>
                <View style={{marginTop: is_mobile ? 0 :50}}>
                  {is_mobile ?
                  <View style={style.testimonial_first_tile}>
                    <View style={style.testimonial_border}>
                      <Text style={style.testimonial_summary_text}>
                      <View style={{textAlign: 'center', alignItems: 'center', marginLeft: 20, float: 'right'}}>
                        <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/9aa1513b-30fe-407f-9d23-3c7c083bd562-1706484409682'}} />
                        <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                          Sachin
                        </Text>
                      </View>"I get to sit down in a small group and actually get to know people without the interruptions of a larger and louder event."
                      </Text>
                      
                    </View>
                  </View> : <View style={[style.testimonial_first_tile, {marginRight: 100}]}>
                    <View style={style.testimonial_border}>
                      <Text style={style.testimonial_summary_text}>"I get to sit down in a small group and actually get to know people without the interruptions of a larger and louder event."</Text>
                      <View style={{textAlign: 'center', alignItems: 'center', marginLeft: 20}}>
                        <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/9aa1513b-30fe-407f-9d23-3c7c083bd562-1706484409682'}} />
                        <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                          Sachin
                        </Text>
                      </View>
                    </View>
                  </View>}
                </View>
                
                <View style={{width: is_mobile ?  'calc(100vw - 40px)' : 400}}>
                  <Text style={{fontFamily: 'Comfortaa', fontSize: 40, marginBottom: 10}}>RSVP for any open spot.</Text>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 20}}>If you're the first to RSVP, you get to choose the demographic of people you'd like to meet and the spot you'd like to meet them. For dinner, we make a reservation for you. For coffee, instead of making a reservation, we choose cafes where everyone in the group can order on their own. The final confirmation on the restaurant will come the day before and, 30 minutes before, a GroupMe chat will open so that you can find your group.</Text>
                </View>
              </View>}

              {is_mobile ?
                <View style={{flexDirection: is_mobile ? 'column' : 'row', marginTop: is_mobile ? 40 : 100}}>
                <View style={{marginTop: 0}}>
                {is_mobile ?
                <View style={style.testimonial_height}>
                <View style={style.testimonial_border}>
                    <Text style={style.testimonial_summary_text}><View style={{textAlign: 'center', alignItems: 'center', marginRight: 20, float: 'left'}}>
                      <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/06e18bef-a7c1-47bd-9a22-740ae015a13c-1705858014214'}} />
                      <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                        Moi
                      </Text>
                    </View>"I get to develop friendships over time instead of forcing a connection from one event."</Text>
                  </View>
                </View> : <View style={[style.testimonial_height, {marginLeft: 100}]}>
                <View style={style.testimonial_border}>
                    <Text style={style.testimonial_summary_text}>"I get to develop friendships over time instead of forcing a connection from one event."</Text>
                    <View style={{textAlign: 'center', alignItems: 'center', marginLeft: 20}}>
                      <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/06e18bef-a7c1-47bd-9a22-740ae015a13c-1705858014214'}} />
                      <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                        Moi
                      </Text>
                    </View>
                  </View>
                </View>}
                </View>
                <View style={{width: is_mobile ?  'calc(100vw - 40px)' : 400, padding: is_mobile ? 30 : 0}}>
                  <Text style={{fontFamily: 'Comfortaa', fontSize: 40, marginBottom: 10}}>Friendships take time.</Text>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 20}}>Our RSVPs are first-come-first-serve because we want to mimic real-world scenarios as close as possible. Think of it a better choice than eating at the bar alone and talking to random strangers. We also don't limit you to meeting up only with strangers. This way, you can socialize with a mix of people you know and also entertain meeting people you don't.</Text>
                </View>
              </View>
              :
              <View style={{flexDirection: is_mobile ? 'column' : 'row', marginTop: 100}}>
                <View style={{width: is_mobile ?  'calc(100vw - 40px)' : 400}}>
                  <Text style={{fontFamily: 'Comfortaa', fontSize: 40, marginBottom: 10}}>Friendships take time.</Text>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 20}}>Our RSVPs are first-come-first-serve because we want to mimic real-world scenarios as close as possible. Think of it a better choice than eating at the bar alone and talking to random strangers. We also don't limit you to meeting up only with strangers. This way, you can socialize with a mix of people you know and also entertain meeting people you don't.</Text>
                </View>
                <View style={{marginTop: 50}}>
                {is_mobile ?
                <View style={style.testimonial_height}>
                <View style={style.testimonial_border}>
                    <Text style={style.testimonial_summary_text}><View style={{textAlign: 'center', alignItems: 'center', marginRight: 20, float: 'left'}}>
                      <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/06e18bef-a7c1-47bd-9a22-740ae015a13c-1705858014214'}} />
                      <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                        Moi
                      </Text>
                    </View>"I get to develop friendships over time instead of forcing a connection from one event."</Text>
                  </View>
                </View> : <View style={[style.testimonial_height, {marginLeft: 100}]}>
                <View style={style.testimonial_border}>
                    <Text style={style.testimonial_summary_text}>"I get to develop friendships over time instead of forcing a connection from one event."</Text>
                    <View style={{textAlign: 'center', alignItems: 'center', marginLeft: 20}}>
                      <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/06e18bef-a7c1-47bd-9a22-740ae015a13c-1705858014214'}} />
                      <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                        Moi
                      </Text>
                    </View>
                  </View>
                </View>}
                </View>
              </View>}


              <View style={{flexDirection: is_mobile ? 'column' : 'row', marginTop: is_mobile ? 40 : 100}}>
              <View style={{marginTop: 10}}>
                {is_mobile ? 
                  <View style={style.testimonial_third_tile}>
                    <View style={style.testimonial_border}>
                        
                        <View style={{textAlign: 'center', alignItems: 'center', marginRight: 20, float: 'right'}}>
                          <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/9b020c06-46be-47f8-b6f5-323686328e07-1701459266114'}} />
                          <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                            Samantha
                          </Text>
                        </View>
                        <Text style={style.testimonial_summary_text}>"I feel very welcome when I know it will just be a few of us chatting in a cozy setting."</Text>
                      </View>
                    </View> : <View style={[style.testimonial_third_tile, {marginRight: 100}]}>
                    <View style={style.testimonial_border}>
                        <View style={{textAlign: 'center', alignItems: 'center', marginRight: 20}}>
                          <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/9b020c06-46be-47f8-b6f5-323686328e07-1701459266114'}} />
                          <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                            Samantha
                          </Text>
                        </View>
                        <Text style={style.testimonial_summary_text}>"I feel very welcome when I know it will just be a few of us chatting in a cozy setting."</Text>
                      </View>
                </View>}
                </View>
                <View style={{width: is_mobile ?  'calc(100vw - 40px)' : 400, padding: is_mobile ? 30 : 0}}>
                  <Text style={{fontFamily: 'Comfortaa', fontSize: 40, marginBottom: 10}}>Check out our Ice-Breakers.</Text>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 20}}>Not all of us are skilled at meeting new people. But, there are a few short ice-breaker questions to remember. In your RSVP confirmation email, we will include a few questions that you can easily ask someone you've just met. Pro-tip: listen to the answer and follow up with more questions!</Text>
                </View>
              </View>

              {is_mobile ?
              <View style={{flexDirection: is_mobile ? 'column' : 'row', marginTop: is_mobile ? 40 : 100}}>
                <View style={{marginTop: 0}}>
                {is_mobile ? 
                <View style={style.testimonial_height}>
                <View style={style.testimonial_border}>
                    <Text style={style.testimonial_summary_text}><View style={{textAlign: 'center', alignItems: 'center', marginRight: 20, float: 'left'}}>
                      <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/6552aad9-a3c7-4b3c-8306-8eb9d671ffd0-1705169404445'}} />
                      <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                        Karan
                      </Text>
                    </View>"I'm single and I like the fact that I get to meet people as friends first without the commitment of a date."</Text>
                  </View>
                </View> : <View style={[style.testimonial_height, {marginLeft: 100}]}>
                <View style={style.testimonial_border}>
                    <View style={{textAlign: 'center', alignItems: 'center', marginRight: 20}}>
                      <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/6552aad9-a3c7-4b3c-8306-8eb9d671ffd0-1705169404445'}} />
                      <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                        Karan
                      </Text>
                    </View>
                    <Text style={style.testimonial_summary_text}>"I'm single and I like the fact that I get to meet people as friends first without the commitment of a date."</Text>
                  </View>
                </View>}
                </View>
                <View style={{width: is_mobile ? 'calc(100vw - 40px)' : 400, padding: is_mobile ? 30 : 0 }}>
                  <Text style={{fontFamily: 'Comfortaa', fontSize: 40, marginBottom: 10}}>Not strictly for singles.</Text>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 20}}>We're not strictly a network for singles. But, we do believe this is a much better way to meet someone. If you're hitting it off, we suggest finding the next spot to mingle with the group. You can also connect with any of the attendees on the site after the meet-up.</Text>
                </View>
              </View>:
              <View style={{flexDirection: is_mobile ? 'column' : 'row', marginTop: is_mobile ? 40 : 100}}>
                <View style={{width: is_mobile ? 'calc(100vw - 40px)' : 400 }}>
                  <Text style={{fontFamily: 'Comfortaa', fontSize: 40, marginBottom: 10}}>Not strictly for singles.</Text>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 20}}>We're not strictly a network for singles. But, we do believe this is a much better way to meet someone. If you're hitting it off, we suggest finding the next spot to mingle with the group. You can also connect with any of the attendees on the site after the meet-up.</Text>
                </View>
                <View style={{marginTop: 50}}>
                {is_mobile ? 
                <View style={style.testimonial_height}>
                <View style={style.testimonial_border}>
                    <Text style={style.testimonial_summary_text}><View style={{textAlign: 'center', alignItems: 'center', marginRight: 20, float: 'left'}}>
                      <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/6552aad9-a3c7-4b3c-8306-8eb9d671ffd0-1705169404445'}} />
                      <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                        Karan
                      </Text>
                    </View>"I'm single and I like the fact that I get to meet people as friends first without the commitment of a date."</Text>
                  </View>
                </View> : <View style={[style.testimonial_height, {marginLeft: 100}]}>
                <View style={style.testimonial_border}>
                    <View style={{textAlign: 'center', alignItems: 'center', marginRight: 20}}>
                      <Image style={{width: 100, height: 100, borderRadius: 100}} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/profile/6552aad9-a3c7-4b3c-8306-8eb9d671ffd0-1705169404445'}} />
                      <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20 }}>
                        Karan
                      </Text>
                    </View>
                    <Text style={style.testimonial_summary_text}>"I'm single and I like the fact that I get to meet people as friends first without the commitment of a date."</Text>
                  </View>
                </View>}
                </View>
              </View>}

              <View style={{textAlign: 'center', alignItems: 'center', paddingVertical: 50, marginTop: is_mobile ? 0 : 100}}>
                <Text style={{fontFamily: 'Ysabeau', fontSize: is_mobile ? 50 : 80}}>Come Join Us!</Text>
              </View>

              <View style={[global_style.whole_width_row_mobile_switch_column, style.newsletter_wrapper]}>
            <View style={[global_style.column, global_style.flex_one, style.newsletter_column]}>
              <Text style={style.newsletter_intro_title}>Sign up for Newsletter</Text>
              <View style={[global_style.row, style.input_spacing]}>
                <Input
                  style={style.input_box} 
                  type="email" value={email_newsletter}  
                  placeholder="Your email" 
                  onChangeText={event => { this.updateEmail(event, 'newsletter') }}
                  onTextInput={event => { this.updateEmail(event, 'newsletter') }}
                  onLayout={() => {}}
                  keyboardType="email-address"
                  />
                  <Button title={<Text style={style.club_button_text}><Icon name="arrow-right" size={20} /></Text>}
                  loading={newsletter_loading}
                  style={style.go_button}
                  style_inactive={style.inactive_go_button}
                  inactive={!email_valid_newsletter}
                  onPress={this.addNewsletter} />
              </View>
              <View>{newsletter_added ? <Text style={style.success_text}>You've been added to our newsletter.</Text> : null}</View>
            </View>

            <View style={[global_style.column, global_style.flex_one, style.register_column]}>
              <Text style={style.newsletter_intro_title}>Register for Free</Text>
              <View style={[global_style.row, style.input_spacing]}>
                <Input  
                  style={style.input_box} 
                  type="email" value={email_register}  
                  placeholder="Your email" 
                  onChangeText={event => { this.updateEmail(event, 'register') }}
                  onTextInput={event => { this.updateEmail(event, 'register') }}
                  onLayout={() => {}}
                  keyboardType="email-address"
                  />
                  <Button title={<Text style={style.club_button_text}><Icon name="arrow-right" size={20} /></Text>}
                  loading={false}
                  style={style.go_button}
                  style_inactive={style.inactive_go_button}
                  inactive={!email_valid_register}
                  onPress={this.emailRegister} />
              </View>
            </View>
          </View>
          <View style={{height: 100}}></View>
            </View>
            
          </View>
        </View>
        </View>
      </View>
    );
  }
}

function HowWorks(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();
    let { slug } = useParams();

    return <HowWorks_Base {...props} slug={slug} navigate={navigate} />
  } else {
    return <HowWorks_Base {...props} navigate={props.navigation.push} />
  }
}

export default HowWorks;
