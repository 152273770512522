import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, Picker, TouchableOpacity, Image } from 'react-native';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Input, Text } from '../../components';
import * as ImagePicker from 'expo-image-picker';
import authController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import PaymentController from '../../controllers/paymentController';
import Utilities from '../../utilities';
import { Header } from '../../containers';
import { style } from './style';
import { global_style } from '../../../global_style';
import { Analytics, PageHit } from 'expo-analytics';
const analytics = new Analytics('G-YCC5QPC8BJ');
import { track, setUserId, setDeviceId, getDeviceId } from '@amplitude/analytics-react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';


class Register_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      tab: 0,
      terms: true,
      min_age: 18,
      max_age: 100,
      agree: false,
      email_register: props.email,
      coffee: props.coffee,
      onboarding_tab: 0,
      user_hobbies: []
    }

    this.passRef = React.createRef();

    this.updateInput = this.updateInput.bind(this);
    this.toggle = this.toggle.bind(this);
    this.register = this.register.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.ageChange = this.ageChange.bind(this);
    this.subscriptionStart = this.subscriptionStart.bind(this);
    this.toggleAgree = this.toggleAgree.bind(this);
    this.pressLogin = this.pressLogin.bind(this);
    this.onDone = this.onDone.bind(this);

    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.verifyCode = this.verifyCode.bind(this);
    this.drawHobbies = this.drawHobbies.bind(this);
  }

  async componentDidMount() {

    track('Register Page');

    analytics.hit(new PageHit('Register'))
      .then(() => {})
      .catch(e => console.log("analytics error", e.message));

    let token = await localStorage.getItem('token');
    if(token) {
      window.location.replace('/dashboard')
    }
      
    let interest_list = await UserController.getInterestList();

    let interests = interest_list && interest_list.interests && interest_list.interests.interests ?  interest_list.interests.interests : null ;

    if(interests) {
      console.log('interests', interests);
      interests = JSON.parse(interests);
    }

    console.log('interest_list', interest_list);

    let onboarding_tab = await localStorage.getItem('onboarding_tab');

    if(onboarding_tab) {
      onboarding_tab = parseInt(onboarding_tab)
    } else {
      onboarding_tab = 0;
    }

    if(onboarding_tab > 4) {
      this.props.navigate('/dashboard')
    }

    console.log('email_register', this.state.email_register)

    this.setState({
      email: this.state.email_register,
      tab: onboarding_tab,
      interest_list: interest_list && interest_list.interests && interest_list.interests.interests ? JSON.parse(interest_list.interests.interests) : {},
      mounted: true,
      interests
    }, () => {
      console.log('this.passRef', this.passRef)
      if(this.state.email_register && this.passRef && this.passRef.focus) {
        this.passRef.focus();
      }
    })
  }

  updateProfileInput(value, input_name) {

    console.log('updting profile ', input_name, " to ", value);

    let { name, job, profile_valid } = this.state;

    if(input_name === 'name') {
      name = value;
    }

    if(input_name === 'job') {
      job = value;
    }

    profile_valid = name && job;

    this.setState({
      [input_name]: value,
      profile_valid
    })
  }

  updateInput(value, name) {

    console.log('updting ', name, " to ", value);

    let { email, password, cpassword, agree, register_valid } = this.state;
    let email_valid = !!email;

    if(name === 'email') {
      email = value;
      email_valid = Utilities.validateEmail(value);
    }

    if(name === 'password') {
      password = value;
    }

    if(name === 'cpassword') {
      cpassword = value;
    }

    register_valid = email && email_valid && password && password === cpassword && agree;

    console.log('email && email_valid && password && password === cpassword && agree', email, email_valid, password, password, cpassword, agree)

    this.setState({
      register_valid,
      email,
      password,
      cpassword
    })
  }

  toggle() {
    this.setState({
      tab: this.state.tab === 0 ? 1 : 0
    })
  }

  async verifyCode() {

    let { verify_code } = this.state;

    if(verify_code && verify_code.length === 6) {
      this.setState({verify_loading: true, message: null}, async () => {
    
        let response = await UserController.verifyCode({verify_code});

        console.log('response', response);

        if(response.success) {
          await localStorage.setItem('onboarding_tab', "a");
          this.setState({
            tab: 'a',
            message: null,
            verify_loading: false,
            profile: {}
          })
        } else {
          this.setState({
            verify_loading: false,
            message: response.error
          });
        }
      })
    }
  }

  async register() {

    let { email, password, cpassword, tab, loading, terms, coffee } = this.state;

    let errors = {
      e: !email,
      p: !password,
      c: !cpassword
    }

    if(!loading && terms) {
      let valid = true;
      let message = "";

      if(!email || !password || !cpassword) {
        valid = false;
        message = "All fields are required"
      }

      if(valid && cpassword !== password) {
        valid = false;
        message = "Passwords do not match"
        errors.p = true;
        errors.c = true;
      }

      if(valid && !Utilities.validateEmail(email)) {
        valid = false;
        message = "Email is not in the appropriate format"
        errors.e = true;
      }

      this.setState({errors, message});

      if(valid) {
        this.setState({loading: true, message: null}, async () => {

          let user_id = await localStorage.getItem('user_id');
          let response = await UserController.register({email, password, user_id});

          console.log('response', response);

          if(response.success) {
            setDeviceId(getDeviceId());
            setUserId(email);
            track('SignUp', {email});
            //rdt('track', 'SignUp');

            await localStorage.setItem('onboarding_tab', "1");
            this.setState({
              tab: 1,
              message: null,
              loading: false,
              profile: {}
            })
          } else {
            this.setState({
              loading: false,
              message: response.error
            });
          }
        })
      } else {
        this.setState({
          message
        });
      }
    } else {
      this.setState({
        errors
      })
    }
  }

  async handleImageUpload() {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    console.log(result);

    if (!result.canceled) {
      this.setState({
        image_data: result.assets[0].uri
      });
    }
  }

  async updateProfile() {
    this.setState({loading: true}, async ()=>{

      let { profile, tab, name, job, bio, host_interested, min_age, max_age, birth_date, birth_month, birth_year, gender, image_data, user_hobbies, hear, other_text, people_meet } = this.state;

      if(!profile) {
        profile = {}
      }

      switch(tab) {
        case 'a':
          profile = {
            ...profile,
            hear, other_text, people_meet
          }
          break;
        case 2:
          profile = {
            name,
            job,
            min_age, max_age,
            birth_month, birth_year,
            bio,
            gender
          }
          break;
        case 3:
          profile = {
            ...profile,
            hobbies: user_hobbies,
            user_hobbies,
            host_interested: !!host_interested
          }
          break;
        default: break;
      }

      await UserController.saveProfile({profile, image_data})

      await localStorage.setItem('onboarding_tab', tab.toString());

      if(tab === 'a') {
        tab = 2;
      } else {
        tab++;
      }

      this.setState({
        loading: false,
        profile,
        tab
      })
    })
  }

  ageChange(age_num, age_name) {
    this.setState({
      [age_name]: parseInt(age_num)
    })
  }

  subscriptionStart(num) {
    let starting = num === 1 ? 'basic_loading' : 'monthly_loading';

    this.setState({[starting]: true}, async () => {
      let response = await PaymentController.createCheckoutSession({num, cancel_url: 'register'});

      if(response && response.success) {
        window.location.replace(response.url);
      }
    });
  }

  toggleAgree() {
    let { email, password, cpassword, agree } = this.state;

    let email_valid = Utilities.validateEmail(email);

    let register_valid = email && email_valid && password && password === cpassword;

    agree = !agree;
    register_valid = register_valid && agree;

    this.setState({
      agree,
      register_valid
    })
  }

  pressLogin() {
    if(this.props.onLoginClick) {
      this.props.onLoginClick()
    } else {
      this.props.navigate('/login')
    }
  }

  onDone() {
    if(this.props.onRegister) {
      this.props.onRegister();
    } else {
      window.location.replace('/dashboard');
    }
  }

  drawHobbies() {
    let { user_hobbies, interests } = this.state;
    user_hobbies = user_hobbies ? user_hobbies : '';
    let hobbies_list = interests ? interests.hobbies : [];

    let hobby_titles = Object.keys(hobbies_list);

    const updateHobby = (hobby) => {
      hobby = hobby.toLowerCase();
      let { user_hobbies } = this.state;
      user_hobbies = user_hobbies ? user_hobbies : '';

      if(user_hobbies.indexOf(`,${hobby},`) !== -1) {
        user_hobbies = user_hobbies.replace(`,${hobby},`, '')
      } else {
        user_hobbies += `,${hobby},`
      }

      let interests_valid = user_hobbies !== ''

      this.setState({ user_hobbies, interests_valid })
    }

    return hobby_titles.map(hobby_title=>{
      return <View style={style.hobbies_edit_wrapper}>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 16}}>{hobby_title}</Text>
        <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
          {hobbies_list[hobby_title].map(hobby=>{
            let selected = user_hobbies.indexOf(`,${hobby.toLowerCase()},`) !== -1 ? style.selected_hobby : {};
            return <TouchableOpacity onPress={()=>{updateHobby(hobby)}}>
                  <Text style={[style.interest_button, selected]}>{hobby}</Text>
                </TouchableOpacity>
          })}
        </View>
      </View>
    })
  }

  togglecPasswordVisibility = () => {
    this.setState({ cpasswordVisible: !this.state.cpasswordVisible });
  }

  togglePasswordVisibility = () => {
    this.setState({ passwordVisible: !this.state.passwordVisible });
  }

  render() {

    const currentYear = new Date().getFullYear();
    const yearsArray = [];

    for (let year = currentYear - 18; year >= currentYear - 100; year--) {
      yearsArray.push(year.toString());
    }

    let { tab, message, register_valid, email, password, cpassword, name, job, hobbies, mounted, agree, profile_valid, interests_valid, host_interested, basic_loading, monthly_loading, loading, birth_month, birth_year, gender, image_data, verify_code, verify_valid, verify_loading, other_text, hear, people_meet, cpasswordVisible, passwordVisible } = this.state;

    people_meet = people_meet ? people_meet : {}

    let age_array = Array.from({ length: 98 }, (value, index) => index).splice(21, 100);

    let birth_month_array =    Array.from({ length: 13 }, (value, index) => index).splice(1, 12);

    let birth_year_array =   yearsArray;

    return (mounted ?
      <View style={style.page_container}>
        <View style={style.lr_container}>
          
{/*           <View style={style.line_breaker}></View> */}

          {/* <View style={style.progress_wrapper}>
            <View style={[style.progress_item, tab === 0 || tab === 1 ? style.selected : {}]}>
              <Text style={style.progress_text}>Register</Text>
            </View>
            <View style={[style.progress_item, tab === 2 ? style.selected : {}]}>
              <Text style={style.progress_text}>Profile</Text>
            </View>
            <View style={[style.progress_item, tab === 3 ? style.selected : {}]}>
              <Text style={style.progress_text}>Preferences</Text>
            </View>
          </View> */}

          {tab === 0 ?
            <View style={style.lr_tab}>
              <Text style={style.login_text}>Register</Text>
              <View style={style.line_breaker}></View>
              <Input style={style.input_box} type="email" value={email} placeholder="email" onChangeText={event => { this.updateInput(event, 'email') }} />
              <View style={style.line_breaker}></View>

              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Input ref={node=>{this.passRef = node}} style={style.input_box} secureTextEntry={!passwordVisible} type="password" value={password} placeholder="password" onChangeText={event => { this.updateInput(event, 'password') }} />
                <TouchableOpacity
                  style={{ position: 'absolute', right: 10 }}
                  onPress={this.togglePasswordVisibility}
                >
                  <Ionicons
                    name={passwordVisible ? 'eye' : 'eye-off'}
                    size={24}
                    color="gray"
                  />
                </TouchableOpacity>
              </View>

              
              <View style={style.line_breaker}></View>

              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Input style={style.input_box} secureTextEntry={!cpasswordVisible} type="password" value={cpassword}  placeholder="confirm password" onChangeText={event => { this.updateInput(event, 'cpassword') }} />
                <TouchableOpacity
                  style={{ position: 'absolute', right: 10 }}
                  onPress={this.togglecPasswordVisibility}
                >
                  <Ionicons
                    name={cpasswordVisible ? 'eye' : 'eye-off'}
                    size={24}
                    color="gray"
                  />
                </TouchableOpacity>
              </View>
              

              {/* phone_send_error ? <Text style={styles.error_message}>{phone_send_error}</Text> : null */}
              <View style={style.check_wrapper}>
                <Input checked={agree} onCheck={this.toggleAgree}
                  check_style={{
                    check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, 
                    hilight_color: {borderColor: '#7ce1e0'},
                    check_text: {
                                  marginLeft: 10,
                                  fontFamily: 'Ysabeau',
                                  fontSize: 16,
                                  width: 190, 
                                  color: 'white'}}}
                  type="check" label={
                      <View style={style.terms_label}>
                        <Text style={style.agree_text}>
                        I agree to DiningSocial's 
                        <TouchableOpacity onPress={()=>{window.open('https://www.diningsocialnj.com/privacy')}}>
                          <Text style={style.link}>Privacy Policy</Text></TouchableOpacity>
                        and<TouchableOpacity onPress={()=>{window.open('https://www.diningsocialnj.com/terms')}}><Text style={style.link}>Terms & Conditions</Text></TouchableOpacity>
                        </Text>
                      </View>} />
              </View>
              <Text style={{color: '#e32652', marginBottom: 10, fontFamily: 'Comfortaa', fontSize: 16}}>{message}</Text>
              <Button title='Register'
                loading={loading}
                style={style.login_button}
                style_inactive={style.inactive_login_button}
                inactive={!register_valid}
                onPress={this.register} />
                <View style={style.register_wrapper}>
                  <Text style={style.dont_text}>Already have an account?</Text>
                  <TouchableOpacity onPress={this.pressLogin} style={style.link_wrap}>
                    <Text style={style.link}>Login here</Text>
                  </TouchableOpacity>
                </View>
            </View> : null}

            {tab === 1 ?
              <View style={style.lr_tab}>
                <Text style={style.login_text}>Verify Code</Text>
                <Text style={[style.label_explainer, {fontSize: 16, justifyContent: 'center', textAlign: 'center'}]}>We've sent a verification code to your email. Please enter it here.</Text>
                <View style={style.line_breaker}></View>

                <Input style={style.input_box} type="text" value={verify_code} placeholder="Verification Code" onChangeText={verify_code => { this.setState({verify_code, verify_valid: verify_code.length === 6}) }} />

                <View style={style.line_breaker}></View>

                <Text style={{color: '#e32652', marginTop: 20, marginBottom: 20, fontFamily: 'Comfortaa', fontSize: 16}}>{message}</Text>
                <Button title='Verify'
                  loading={verify_loading}
                  style={style.login_button}
                  style_inactive={style.inactive_login_button}
                  inactive={!verify_valid}
                  onPress={this.verifyCode} />


                <View style={[style.register_wrapper, {justifyContent: 'center'}]}>
                  <TouchableOpacity onPress={()=>{
                    this.setState({
                      tab: 0,
                      message: null
                    })
                  }} style={style.link_wrap}>
                    <Text style={style.link}>Change email</Text>
                  </TouchableOpacity>
                </View>

                  
              </View> : null}

          {tab === 'a' ?
            <View style={style.lr_tab}>
              <Text style={style.login_text}>Help us Improve</Text>
              <Text style={style.label_explainer}>Tell us a bit about what you're looking for.</Text>

              <Text style={style.input_label}>How Did You Hear About Us?</Text>
              {/* <Text style={style.label_explainer}>The name field will mostly be used for the host to identify you.</Text> */}
              <View style={[style.a_row, {width: 300, alignSelf: 'center', flexDirection: 'column'}]}>
                  <View style={style.age_wrapper}>
                    <Picker
                        selectedValue={gender}
                        mode={'dialog'}
                        style={style.drop_style_profile}
                        onValueChange={(event)=>this.updateProfileInput(event, 'hear')}
                      >
                        {['Choose an option...', 'Instagram', 'Facebook', 'Reddit', 'Google', 'Hoboken Girl', 'Other'].map(v => {
                          return <Picker.Item
                            fontSize={16}
                            style={{ fontSize: 16 }}
                            key={v}
                            label={v}
                            value={v}
                          />
                        })}
                      </Picker>
                  </View>
                  
                    {hear === 'Other' ? 
                    <View style={{marginTop: 10}}>
                      <Input style={style.input_box} type="text" value={other_text} placeholder="How did you hear about us?" onChangeText={event => { this.setState({other_text: event}) }} />
                    </View>
                    : null}

                  
              </View>

              <Text style={style.input_label}>Each meet-up, I want to meet </Text>
              
              <View>
              {['People I do not know', `Mix of people I know and don't know`, 'Only my gender', 'Mixed genders', 'Only my age range', 'Mix of age ranges' ].map(v => {
                    return <Input checked={people_meet[v]} onCheck={()=>{
                        let { people_meet } = this.state;

                        people_meet = people_meet ? people_meet : {}

                        this.setState({
                        people_meet: {
                          ...people_meet,
                          [v]: !people_meet[v]
                        }
                      })}}
                      check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
                        flexWrap: 'wrap',
                          overflowWrap: 'anywhere',
                          textWrap: 'wrap',
                          marginLeft: 10,
                          fontFamily: 'Ysabeau',
                          fontSize: 16,
                          color: 'black'}}}
                      type="check" label={<Text style={global_style.normal_text_dark_medium}>{v}</Text>} />
                  })}
                
              </View>
                
              <View style={style.line_breaker}></View>
              
              <Text style={{color: '#e32652', marginTop: 20, marginBottom: 20, fontFamily: 'Comfortaa', fontSize: 16}}>{message}</Text>
              <Button title='Next'
                loading={loading}
                style={style.login_button}
                style_inactive={style.inactive_login_button}
                onPress={this.updateProfile} />
              </View> : null}

            {tab === 2 ?
            <View style={style.lr_tab}>
              <Text style={style.login_text}>Profile</Text>
              <Text style={style.label_explainer}>Tell us a bit about yourself so we can show you relevant events.</Text>
              <TouchableOpacity style={style.skip_wrap} onPress={()=>{this.setState({tab: 3})}}><Text style={style.link}>Skip for Now</Text></TouchableOpacity>

              <View style={style.edit_row}>
                <View style={{position: 'relative'}}>
                  <TouchableOpacity style={style.profile_image_wrapper} onPress={this.handleImageUpload} >
                    <Image style={style.profile_image} resizeMode={'cover'} source={{uri: image_data ? image_data : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} />
                    <View><Text style={style.edit_photo_button}>Edit</Text></View>
                  </TouchableOpacity>
                  
                </View>
              </View>


              <Text style={style.input_label}>Name</Text>
              {/* <Text style={style.label_explainer}>The name field will mostly be used for the host to identify you.</Text> */}
              <Input style={style.input_box} type="text" value={name} placeholder="Name" onChangeText={event => { this.updateProfileInput(event, 'name') }} />


              <Text style={style.input_label}>Job</Text>
              
              <Input style={style.input_box} type="text" value={job} placeholder="Job" onChangeText={event => { this.updateProfileInput(event, 'job') }} />


              <Text style={style.input_label}>I identify as</Text>
              {/* <Text style={style.label_explainer}>The gender field will only be used for matching gender-specific events like "Girls brunch".</Text> */}
              <View style={[style.a_row, {width: 300, alignSelf: 'center'}]}>
                  <View style={style.age_wrapper}>
                    <Picker
                        selectedValue={gender}
                        mode={'dialog'}
                        style={style.drop_style_profile}
                        onValueChange={(value)=>this.updateProfileInput(value, 'gender')}
                      >
                        {['Choose your gender...', 'Female', 'Male', 'Non-binary'].map(v => {
                          return <Picker.Item
                            fontSize={16}
                            style={{ fontSize: 16 }}
                            key={v}
                            label={v}
                            value={v}
                          />
                        })}
                      </Picker>
                  </View>
              </View>

               <View style={{alignSelf: 'flex-start'}}>
                  <Text style={style.input_label}>Your age</Text>
                  {/* <Text style={style.label_explainer}>Please let us know your birth month and year so we can match you up appropriately.</Text> */}
                  <View style={[style.a_row, {width: 145, alignSelf: 'center'}]}>
                      <View style={style.age_wrapper}>
                        <Text style={style.input_label_small}>Month</Text>
                        <Picker
                            selectedValue={birth_month}
                            mode={'dialog'}
                            style={style.drop_style}
                            onValueChange={(value)=>this.ageChange(value, 'birth_month')}
                          >
                            {birth_month_array.map(v => {
                              return <Picker.Item
                                fontSize={16}
                                style={{ fontSize: 16 }}
                                key={v}
                                label={v}
                                value={v}
                              />
                            })}
                          </Picker>
                      </View>
                      <View>
                        <Text style={style.input_label_small}>Year</Text>
                      <Picker
                          selectedValue={birth_year}
                          mode={'dialog'}
                          style={style.drop_style}
                          onValueChange={(value)=>this.ageChange(value, 'birth_year')}
                        >
                          {birth_year_array.map(v => {
                            return <Picker.Item
                              fontSize={20}
                              style={{ fontSize: 20 }}
                              key={v}
                              label={v}
                              value={v}
                            />
                          })}
                        </Picker>
                      </View>
                  </View>
                </View>
                
              <View style={style.line_breaker}></View>
              <TouchableOpacity style={style.skip_wrap_center} onPress={()=>{this.setState({tab: 3})}}><Text style={style.link}>Skip for Now</Text></TouchableOpacity>
              <View style={style.line_breaker}></View>
              
              <Text style={{color: '#e32652', marginTop: 20, marginBottom: 20, fontFamily: 'Comfortaa', fontSize: 16}}>{message}</Text>
              <Button title='Next'
                loading={loading}
                style={style.login_button}
                style_inactive={style.inactive_login_button}
                inactive={!profile_valid}
                onPress={this.updateProfile} />
              </View> : null}

          {tab === 3 ?
            <View style={[style.lr_tab, style.preferences_tab]}>
              <Text style={style.login_text}>Interests</Text>
              <Text style={style.label_explainer}>Spruse up your profile and potentially match up with others based on your interests.</Text>
              <TouchableOpacity style={style.skip_wrap} onPress={()=>{this.setState({tab: 4})}}><Text style={style.link}>Skip for Now</Text></TouchableOpacity>
              <View style={style.line_breaker}></View>

              <View style={style.interests_wrapper}>

                <View style={style.interests_column_1}>
                  <Text style={[style.input_label, {fontSize: 20}]}>Hobbies</Text>
                  {/* <Text style={[style.explainer_text_two]}>Members will be able to search for you based on your hobbies :-)</Text> */}

                  <View style={style.interests_column_1_content}>
                    <View style={style.hobbies_wrapper}>
                      {this.drawHobbies()}
                    </View>
                  </View>
                </View>

                {/* <Text style={style.secondary_title}>Interested in hosting our events?</Text>
                <Text style={style.explainer_text_two}>It's not hard. You just need to be interested in people ☺️. We'll guide you through it.</Text>
                <View style={{alignContent: 'flex-start', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: -10, marginBottom: 20 }}>
                  <Input type={"check"}
                    check_style={{hilight_color: {borderColor: '#7ce1e0'}}} 
                   onCheck={()=>{this.setState({host_interested: !this.state.host_interested})}} checked={host_interested} label={<Text style={style.explainer_text_two}> Yes, I'm interested!</Text>} />
                </View> */}
              </View>
              <View style={style.line_breaker}></View>
              <TouchableOpacity style={style.skip_wrap_center} onPress={()=>{this.setState({tab: 4})}}><Text style={style.link}>Skip for Now</Text></TouchableOpacity>
              <View style={style.line_breaker}></View>
              <Text style={{color: '#e32652', marginTop: 20, marginBottom: 20, fontFamily: 'Comfortaa', fontSize: 16}}>{message}</Text>
              <Button title='Next'
                loading={loading}
                style={style.login_button}
                style_inactive={style.inactive_login_button}
                inactive={!interests_valid}
                onPress={this.updateProfile} />

            </View> : null}

            {tab === 4 ?
            <View style={[style.lr_tab, {maxWidth: 400}]}>
              <View style={style.line_breaker}></View>
              <View style={style.intro}>
                <Text style={[style.intro_text, {lineHeight: 38, fontSize: 30, marginBottom: 30, fontFamily: 'Ysabeau'}]}>
                  Thank you for registering! What happens now? 
                </Text>
                <Text style={style.intro_text}>
                  RSVP for any events on the calendar that you'd like to attend. If you don't see an event for a date that you're available, mark your availability and let us know whom you'd like to meet.
                </Text>
              </View>

              <TouchableOpacity onPress={()=>{window.location.replace('/dashboard')}}>
                <Text style={{color: 'white', backgroundColor: '#e32652', fontFamily: 'Ysabeau', borderRadius: 2, fontSize: 24, paddingVertical: 10, paddingHorizontal: 30}}>Go to Calendar </Text>
              </TouchableOpacity>

            </View> : null}

      
            {/* <View style={[style.lr_tab, {backgroundColor: 'black'}]}>
              <Text style={[style.login_text, {color: 'white'}]}>Subscription</Text>
              <View style={style.line_breaker}></View>
              <View style={style.intro}>
                <Text style={style.intro_text}>
                  We've worked hard so it's easy for you to choose.
                </Text>
                <Text style={style.intro_text}>
                  Choose the best plan depending on how many times you're planning on joining us monthly.
                </Text>
              </View>

              <TouchableOpacity onPress={()=>{window.open('/pricing')}}>
                <Text style={style.pricing_text}>Pricing details </Text>
              </TouchableOpacity>

            <View style={style.plans_wrapper}>
              <View style={style.one_plan}>
                <View style={style.plan_cap}>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 26, color: 'white'}}>Free</Text>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 20, color: 'white'}}><Text style={{color: '#e32652',fontFamily: 'Ysabeau', fontSize: 30 }}>$0</Text> / month</Text>
                </View>
                <View style={{backgroundColor: 'white'}}>
                  <View>
                    <Text style={{fontFamily: 'Comfortaa', fontSize: 14, padding: 20, borderBottom: 'solid 2px #e32652', lineHeight: 20}}>Join without a subscription and simply pay a small attendance fee per event.</Text>
                  </View>
                  <View>
                      <View style={{ padding: 20, borderBottom: 'solid 2px #e32652', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                        <View style={{flex: 1}}>
                          <FontAwesome name="coffee" size={30} color="#e32652" />
                        </View>
                        <View style={{flex: 4}}>
                          <Text style={{textAlign: 'center', fontFamily: 'Comfortaa', fontSize: 14,}}><Text style={{fontFamily: 'Comfortaa', fontSize: 14, color: '#e32652'}}>$5</Text> / Coffee Invites</Text>
                        </View>
                    </View>
                  </View>
                  <View>
                      <View style={{ padding: 20, borderBottom: 'solid 2px #e32652', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                        <View style={{flex: 1}}>
                        <MaterialIcons name="dinner-dining" size={33} color="#e32652" />
                        </View>
                        <View style={{flex: 4}}>
                          <Text style={{textAlign: 'center', fontFamily: 'Comfortaa', fontSize: 14,}}><Text style={{fontFamily: 'Comfortaa', fontSize: 14, color: '#e32652'}}>$17</Text> / Dinner, Brunch RSVP</Text>
                        </View>
                    </View>
                  </View>
                  <View>
                      <Text style={{fontFamily: 'Comfortaa', fontSize: 14, padding: 20, borderBottom: 'solid 2px #e32652'}}>Join any number of our free events.</Text>
                  </View>
                  <View>
                  <TouchableOpacity onPress={this.onDone} style={[style.join_button, style.join_button_free]}>
                    <Text style={style.join_button_text}>Continue for Free</Text>
                  </TouchableOpacity>
                  </View>
                </View>
              </View>

              <View style={style.one_plan}>
                <View style={style.plan_cap}>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 26, color: 'white'}}>Basic</Text>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 20, color: 'white'}}><Text style={{color: '#e32652',fontFamily: 'Ysabeau', fontSize: 30 }}>$9.99</Text> / month</Text>
                </View>
                <View style={{backgroundColor: 'white'}}>
                <View>
                    <Text style={{fontFamily: 'Comfortaa', fontSize: 14, padding: 20, borderBottom: 'solid 2px #e32652', lineHeight: 20}}>Join and attend an unlimited number of coffee invites with a discount on dinners, brunches.</Text>
                  </View>
                <View>
                    <View style={{ padding: 20, borderBottom: 'solid 2px #e32652', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                        <View style={{flex: 1}}>
                          <FontAwesome name="coffee" size={30} color="#e32652" />
                        </View>
                        <View style={{flex: 4}}>
                          <Text style={{textAlign: 'center', fontFamily: 'Comfortaa', fontSize: 14,}}>Unlimited Coffee Invites</Text>
                        </View>
                    </View>
                  </View>
                  <View>
                      <View style={{ padding: 20, borderBottom: 'solid 2px #e32652', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                        <View style={{flex: 1}}>
                        <MaterialIcons name="dinner-dining" size={33} color="#e32652" />
                        </View>
                        <View style={{flex: 4}}>
                          <Text style={{textAlign: 'center', fontFamily: 'Comfortaa', fontSize: 14,}}><Text style={{fontFamily: 'Comfortaa', fontSize: 14, color: '#e32652'}}>$8</Text> / Dinner, Brunch RSVP</Text>
                        </View>
                    </View>
                  </View>
                  <View>
                      <Text style={{fontFamily: 'Comfortaa', fontSize: 14, padding: 20, borderBottom: 'solid 2px #e32652'}}>Message members you've met and get notified when they join another event.</Text>
                  </View>
                  <View>
                    <Button loading={basic_loading} onPress={()=>this.subscriptionStart(1)}
                              style={[style.join_button, style.join_button_monthly]} title={
                                <View style={{width: 108}}><Text style={style.join_button_text}>Continue for $9.99/mo</Text></View>} />
                  </View>
                </View>
              </View>

              <View style={style.one_plan}>
                <View style={{flexDirection: 'column', backgroundColor: '#222326', borderTopLeftRadius: 10, borderTopRightRadius: 10, alignContent: 'center', alignItems: 'center', padding: 20}}>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 26, color: 'white'}}>Premium</Text>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 20, color: 'white'}}><Text style={{color: '#e32652',fontFamily: 'Ysabeau', fontSize: 30 }}>$24.99</Text>  / month</Text>
                </View>
                <View style={{backgroundColor: 'white'}}>
                  <View>
                    <Text style={{fontFamily: 'Comfortaa', fontSize: 14, padding: 20, borderBottom: 'solid 2px #e32652', lineHeight: 20}}>Join to attend an unlimited number of coffee invites, dinners and brunches.</Text>
                  </View>
                  <View>
                    <View style={{ padding: 20, borderBottom: 'solid 2px #e32652', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                        <View style={{flex: 1}}>
                          <FontAwesome name="coffee" size={30} color="#e32652" />
                        </View>
                        <View style={{flex: 4}}>
                          <Text style={{textAlign: 'center', fontFamily: 'Comfortaa', fontSize: 14,}}>Unlimited Coffee Invites</Text>
                        </View>
                    </View>
                  </View>
                  <View>
                      <View style={{ padding: 20, borderBottom: 'solid 2px #e32652', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                        <View style={{flex: 1}}>
                        <MaterialIcons name="dinner-dining" size={33} color="#e32652" />
                        </View>
                        <View style={{flex: 4}}>
                          <Text style={{textAlign: 'center', fontFamily: 'Comfortaa', fontSize: 14,}}>Unlimited Dinner, Brunch RSVPs</Text>
                        </View>
                    </View>
                  </View>
                  <View>
                    <Text style={{fontFamily: 'Comfortaa', fontSize: 14, padding: 20, borderBottom: 'solid 2px #e32652'}}>Discounts on other, one-off events.</Text>
                  </View>
                  <View>
                  <Button loading={monthly_loading} onPress={()=>this.subscriptionStart(2)}
                              style={[style.join_button, style.join_button_monthly]} title={
                                <View style={{width: 108}}><Text style={style.join_button_text}>Continue for $30/mo</Text></View>} />
                  </View>
                </View>
              </View>
              
            </View>
            </View> */}

            
        </View>
      </View> : null
    );
  }
}

function RegisterScreen(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();

    let location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    let email = searchParams.get('email');
    let coffee = searchParams.get('coffee');

    return <Register_Base {...props} email={email} coffee={coffee && coffee === 'true' ? true : false} navigate={navigate} />
  } else {
    return <Register_Base {...props} navigate={props.navigation.push} />
  }
}

export default RegisterScreen;
