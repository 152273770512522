import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Text } from '../../components';
import AuthController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import Utilities from '../../utilities';
import { Header } from '../../containers';
import { style } from './style';
import { Analytics, PageHit } from 'expo-analytics';
const analytics = new Analytics('G-YCC5QPC8BJ');
import { init, track } from '@amplitude/analytics-react-native';
import { config } from '../../../config';

class Feedback_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: null,
      notes: null,
      slug: props.slug,
      event_id: props.event_id,
      feedback_sent: false,
      feedback: {}
    }

    this.updateInput = this.updateInput.bind(this);
    this.sendFeedback = this.sendFeedback.bind(this);
  }

  async componentDidMount() {
    track('Date Feedback Page');
    analytics.hit(new PageHit('Date_Feedback'))
      .then(() => {})
      .catch(e => console.log("analytics error", e.message));
  
    let token = await localStorage.getItem('token');
    let isLoggedIn = !!token;
    let { event_id } = this.state;

    if(isLoggedIn) {
      let event = await UserController.getUserEvent({event_id});
      let user = await UserController.getUser(true);
      let user_id = user && user.user ? user.user.id : null;

      if(event && event.event_data && event.event_data.rsvps && event.event_data.rsvps.indexOf(user_id) !== -1) {
        let place_id = event.event_data.chosen_where[0]
        let place = config.places.filter(a=>a.id === place_id);
        place = place && place.length ? place[0] : null;

        this.setState({
          mounted: true,
          event: event.event_data,
          place
        })
      } else {
        window.location.replace(`/feedback`);
      } 
    } else {
        let redirect_url = encodeURIComponent(`/review/${event_id}`);
        window.location.replace(`/login/${redirect_url}`);
    }
  }

  updateInput(value, name) {
    let { feedback } = this.state;

    console.log('value, name', value, name);

    if(value && value.target) {
      value = !this.state.feedback[name];
    }

    console.log('value', value);

    feedback = {
      ...feedback,
      [name]: value
    }

    this.setState({ feedback })
  }

  async sendFeedback() {
    this.setState({loading: true}, async ()=>{
      let { feedback, event_id } = this.state;
      feedback = JSON.stringify(feedback);
      let data = { feedback, event_id };

      let response = await UserController.sendUserFeedback(data);

      if(response.success) {
        this.setState({
          loading: false,
          feedback_sent: true
        })
      } else {
        this.setState({
          loading: false,
          message: response.error
        });
      }
    })
  }

  drawInput(a) {

    let { type, content, placeholder, name } = a;

    let content_element;

    switch(type) {
      case "title":
        content_element = <Text style={style.title}>{content}</Text>;
        break;
      case "input":
        content_element = <View><Input style={style.input_box_multi} multiline={true} type="text" value={this.state.feedback[name]}  placeholder={placeholder} onChangeText={event => { this.updateInput(event, name) }} />
        <View style={style.line_breaker}></View></View>;
        break;
      case "label":
        content_element = <Text style={style.label}>{content}</Text>;
        break;
      case "check":
        content_element = <View style={style.check_wrapper}><Input type={"check"} label={<View style={style.check_text_wrapper}><Text style={style.check_text}>{placeholder}</Text></View>} onCheck={event => { this.updateInput(event, name) }} checked={this.state.feedback[name]} /></View>;
        break;
      default: 
        content_element = <></>
        break;
    }

    return <View>
      {content_element}
    </View>
  }

  render() {
    let { event, place, feedback_sent, loading, message, mounted } = this.state;

    let restaurant_name, dinner_date;
    if(mounted) {
      restaurant_name = place.name;
      dinner_date = event.selected_day;
    }

    let form = [
      {
        type: 'title',
        content: `The Restaurant - ${restaurant_name}`
      },
      {
        type: 'label',
        content: `How did you enjoy the food at ${restaurant_name}?`
      },
      {
        type: 'input',
        name: "restaurant_food",
        placeholder: "How was the food?"
      },
      {
        type: 'label',
        content: `How was the price of the food at ${restaurant_name}?`
      },
      {
        type: 'input',
        name: "restaurant_price",
        placeholder: "How was the price?"
      },
      {
        type: 'label',
        content: `How was the service at ${restaurant_name}?`
      },
      {
        type: 'input',
        name: "restaurant_service",
        placeholder: "How was the service?"
      },
      {
        type: 'label',
        content: `How was the vibe at ${restaurant_name}?`
      },
      {
        type: 'input',
        name: "restaurant_vibe",
        placeholder: "How was the vibe?"
      },
      {
        type: 'label',
        content: `Would you come back (individually or with our group) to ${restaurant_name} and is there anything else you'd like to add about the restaurant?`
      },
      {
        type: 'input',
        name: "restaurant_anything",
        placeholder: "Anything else?"
      },

      {
        type: 'title',
        content: "The Group"
      },
      {
        type: 'label',
        content: "How did you enjoy the experience and format of the dinner? (i.e. the icebreakers, nominating other people to go next, the host being there, etc.)"
      },
      {
        type: 'input',
        name: "group_experience",
        placeholder: "How was the experience?"
      },
      {
        type: 'label',
        content: "How was the host and do you think having a host is necessary for our dinners?"
      },
      {
        type: 'input',
        name: "group_host",
        placeholder: "How was the host?"
      },
      {
        type: 'label',
        content: "Do you have any other feedback or ideas on how to improve our structure?"
      },
      {
        type: 'input',
        name: "group_anything",
        placeholder: "Enything else about the experience?"
      },
      {
        type: 'title',
        content: "Last, but not least..."
      },
      {
        type: 'label',
        content: "Is there a reason you picked this particular day vs the other? (ex; was it the restaurant? was it just the day you were available?)"
      },
      {
        type: 'input',
        name: "last_day",
        placeholder: "Is there a reason for the day?"
      },
      {
        type: 'label',
        content: "Anything else about the whole experience you'd like to add?"
      },
      {
        type: 'input',
        name: "last_anything",
        placeholder: "Anything else?"
      },
      {
        type: 'label',
        content: "Would you allow us to post a testimonial (if positive :-) from your feedback on our site?"
      },
      {
        type: 'check',
        name: "testimonial",
        placeholder: "Yes, include my testimonial"
      }
    ];

    return (
      mounted ? 
      <View style={style.page_container}>
        <View style={style.lr_container}>
          <Text style={style.main_title}>Feedback</Text>
          
          {!feedback_sent ?
              <View style={style.wrapper}>
                  <Text style={style.explainer_intro}>Thank you for attending dinner at <Text style={style.underline}>{restaurant_name}</Text> on <Text style={style.underline}>{dinner_date}</Text>. Please let us know how we can improve your experience.</Text>
                {form.map(a=>{return this.drawInput(a)})}
                <View style={style.line_breaker}></View>

                  <Button title='Submit Feedback'
                    loading={loading}
                    style={style.login_button}
                    style_inactive={style.inactive_login_button}
                    onPress={this.sendFeedback} />
                  <Text style={style.error_text}>{message}</Text>
                  <View style={style.line_breaker}></View>
                  <View style={style.line_breaker}></View>
            </View>: <View>
            <Text style={style.intro}>
              Thank you for your feedback. We'll be in touch with any necessary follow-up.
            </Text>
              </View>}

          
        </View>
      </View> : null
    );
  }
}

function Feedback(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();
    let { slug, event_id } = useParams();

    return <Feedback_Base {...props} slug={slug} event_id={event_id} navigate={navigate} />
  } else {
    return <Feedback_Base {...props} navigate={props.navigation.push} />
  }
}

export default Feedback;
