import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity, Image, Animated, Dimensions } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Text } from '../../components';
import authController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import EventController from '../../controllers/eventController';
import Utilities from '../../utilities';
import { FAQ } from '../../containers';
import { style } from './style';
import { Analytics, PageHit } from 'expo-analytics';
const analytics = new Analytics('G-YCC5QPC8BJ');
import { init, track } from '@amplitude/analytics-react-native';

import { MaterialIcons } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { Video } from 'expo-av';
import { global_style } from '../../../global_style';

class Mission_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mounted: true
    }

    this.goToPricing = this.goToPricing.bind(this);

  }

  async componentDidMount() {
    track('Missions Page');
    analytics.hit(new PageHit('Mission'))
    .then(() => {})
    .catch(e => console.log("analytics error", e.message));
  }

  goTo(link) {
    window.open(link)
  }

  async goToPricing() {
    let token = await localStorage.getItem('token');
    let isLoggedIn = !!token;

    if(isLoggedIn) {
      this.props.navigate('/dashboard/subscription')
    } else {
      this.props.navigate('/register')
    }
  }

  render() {

    let width = Dimensions.get('window').width;
    let height = Dimensions.get('window').height;

    let is_mobile = width < 500;

    return (<View style={style.explainer_section}>
      <View style={{backgroundColor: '#f7f8fc'}}>
      <View style={{flexDirection: 'column'}}>
            <View ref={node=>this.dining=node} style={{flexDirection: 'row', borderColor: '#999999', borderBottomWidth: 1}}><Text style={style.section_title}>Our Mission</Text></View>
      
              <View style={{flexDirection: 'row', width: '90%', margin: 'auto'}}>
                <Video
                  source={{ uri: 'https://diningsocial-assets.s3.amazonaws.com/lunas.MP4' }}
                  style={[{height: 550, width: '100%'}]}
                  videoStyle={{position: 'relative', top: 0}}
                  useNativeControls={false}
                  isLooping={true}
                  shouldPlay={true}
                  resizeMode={'cover'}
                  rate={.7}
                  volume={0}
                  isMuted={true}
                />
              </View>
   
          </View>
          <View style={{flexDirection: 'column'}}>

                  <View style={{ width: is_mobile ? '90%' : 900, margin: 'auto'}}>
                    <Text style={style.dining_sub_title}>
                      Let's create a space that fosters social interaction
                    </Text>
                    <View style={{marginBottom: 20}}>
                      <Text style={[style.dining_text]}>
                      The people in your circle significantly influence your life. And yet, building meaningful connections has become increasingly challenging in today's society. Cafes are filled with busy people focused on work, bars often attract questionable characters, and networking events rarely foster genuine connections. It's hard to find a place where people are truly nice and interested in getting to know you.
                      </Text>
                    </View>
                    <View style={{marginBottom: 20}}>
                      <Text style={[style.dining_text]}>
                      We aim to create a space where you can always come to socialize and connect with others over the course of a meal, rather than through brief five-minute introductions. Our goal is to provide you with a network that values connections developed over time, transcending beyond a few shared interests.
                      </Text>
                    </View>
                    <View style={{marginBottom: 60}}>
                      <Text style={[style.dining_text]}>
                      To achieve our mission, we promote a set of principles at our gatherings. While we don't expect you to be a socializing expert immediately, we do ask that you make an effort by following the principles outlined below.
                      </Text>
                    </View>

                </View>
                <View style={{width: '100%'}}>
                    <View style={{}}>
                      <View style={{flexDirection: is_mobile ? 'column' : 'row', borderBottomWidth: 1, borderColor: '#9d9b9b', borderTopWidth: 1, paddingTop: is_mobile ? 20 : 'unset'}}>
                   
                          <View style={{width: is_mobile ? '90%' : '40%', borderRightWidth: is_mobile ? 0 : 1, borderColor: '#9d9b9b', justifyContent: 'center', margin: is_mobile ? 'auto' : 'unset'}}>
                            <View style={{paddingRight: is_mobile ? 0 : 50}}>
                              <Text style={{fontFamily: 'Ysabeau', fontSize: 50, textTransform: 'uppercase', marginBottom: 20, marginLeft: is_mobile ? 10 : 50}}>Social Effort</Text>
                              <View style={{height: 230}}>
                                <Text style={[style.dining_text, {marginLeft: is_mobile ? 0 : 50}]}>
                                  We're all responsible for the atmosphere we're in. Let's make each other feel welcome and respected. When you come to our DiningSocial outings, we ask that you put in an effort to participate in the conversation by listening and following up with questions. 
                                </Text>
                              </View>
                            </View>
                          </View>
                          <View style={{width: is_mobile ? '100%' : '60%'}}>
                            <View style={{marginBottom: 50, marginLeft: is_mobile ? 0 : 50, marginRight: is_mobile ? 0 : 50, marginTop: 50}}>
                              <Image style={{height: 400, width: '100%'}} resizeMode='cover' source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/social_effort_2.JPG'}} />
                            </View>
                          </View>
                          
                      </View>
                    </View>

                    <View style={{}}>
                      <View style={{flexDirection: is_mobile ? 'column' : 'row', borderBottomWidth: 1, paddingBottom: is_mobile ? 20 : 'unset', borderColor: '#9d9b9b'}}>
                   
                          <View style={{width: is_mobile ? '100%' : '60%'}}>
                            <View style={{marginBottom: 50, marginLeft: is_mobile ? 0 : 50, marginRight: is_mobile ? 0 : 50, marginTop: 50}}>
                              <Image style={{height: 400, width: '100%'}} resizeMode='cover' source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/curiosity.png'}} />
                            </View>
                          </View>
                          <View style={{width: is_mobile ? '90%' : '40%', borderLeftWidth:  is_mobile ? 0 : 1, borderColor: '#9d9b9b', justifyContent: 'center', margin: is_mobile ? 'auto' : 'unset'}}>
                            <View style={{paddingLeft: is_mobile ? 0 : 50}}>
                              <Text style={{fontFamily: 'Ysabeau', fontSize: 50, textTransform: 'uppercase', marginBottom: 20, }}>CURIOSITY</Text>
                              <View style={{height: 230}}>
                                <Text style={[style.dining_text, {marginRight: is_mobile ? 0 : 50}]}>
                                  Each one of us has a unique story. A person might look like someone you know, but assuming that they are the same is an erroneous notion. Be curious about the people around you and find out who they are. You never know whom you'll connect with.
                                </Text>
                              </View>
                            </View>
                          </View>
                          
                      </View>
                    </View>

                    <View style={{}}>
                      <View style={{flexDirection: is_mobile ? 'column' : 'row', borderBottomWidth: 1, borderColor: '#9d9b9b', borderTopWidth: 1, paddingTop: is_mobile ? 20 : 'unset'}}>
                   
                          <View style={{width: is_mobile ? '90%' : '40%', borderRightWidth: is_mobile ? 0 : 1, borderColor: '#9d9b9b', justifyContent: 'center', margin: is_mobile ? 'auto' : 'unset'}}>
                            <View style={{paddingRight: is_mobile ? 0 : 50}}>
                              <Text style={{fontFamily: 'Ysabeau', fontSize: is_mobile ? 48 : 50, textTransform: 'uppercase', marginBottom: 20, marginLeft: is_mobile ? 10 : 50}}>OPEN NETWORKING</Text>
                              <View style={{height: 230}}>
                                <Text style={[style.dining_text, {marginLeft: is_mobile ? 0 : 50}]}>
                                Networking isn't transactional. By only talking to the people that you're looking to meet, you're limiting your chances of achieving that goal. Everyone comes in with their own respective network. By communicating your goals clearly and finding out about theirs, you both open up your networks to each other.
                                </Text>
                              </View>
                            </View>
                          </View>
                          <View style={{width: is_mobile ? '100%' : '60%'}}>
                            <View style={{marginBottom: 50, marginLeft: is_mobile ? 0 : 50, marginRight: is_mobile ? 0 : 50, marginTop: 50}}>
                              <Image style={{height: 400, width: '100%'}} resizeMode='cover' source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/open_networking.png'}} />
                            </View>
                          </View>
                          
                      </View>
                    </View>
                  </View>
                  <View style={{position: 'relative', justifyContent: 'center', alignItems: 'center', width: '80%', backgroundColor: '#1e1c1c', height: 300, textAlign: 'center', margin: 'auto', borderTopWidth: 1, borderTopColor: '#9d9b9b', marginBottom: 100, marginTop: 100}} >
                    <Text style={{color: 'white', fontFamily: 'Ysabeau', fontSize: 40, marginBottom: 10}}>Want to find out more?</Text>
                    {/* <Text style={{color: 'white', fontFamily: 'Ysabeau', fontSize: 30, marginBottom: 30}}>We're open to chatting!</Text> */}
                    <TouchableOpacity onPress={this.props.toggleContact} ><Text style={[style.three_buttons_button, {width: 250}]}>Reach out for a chat.</Text></TouchableOpacity>
                  </View>
                  <View style={{}}>
                    <View style={{flexDirection: 'row', borderColor: '#9d9b9b', borderBottomWidth: 1, borderTopWidth: 1}}><Text style={style.section_title}>Founder Story</Text></View>
                    
                      <View style={{flexDirection: is_mobile ? 'column' : 'row'}}>
                   
                          <View style={{width: is_mobile ? '100%' : '40%',  borderColor: '#9d9b9b', justifyContent: 'center'}}>
                            <View style={{marginHorizontal: is_mobile ? 20 : 50}}>
                            <Text style={{fontFamily: 'Ysabeau', fontSize: 35, textTransform: 'uppercase', marginBottom: 0, marginTop: is_mobile ? 20 :'unset' }}>Welcome!</Text>
                              <Text style={{fontFamily: 'Ysabeau', fontSize: is_mobile ? 30 : 50, textTransform: 'uppercase', marginBottom: 20, }}>I'm Aleksandra</Text>
                              <View style={{}}>
                                <Text style={[style.dining_text, {marginRight: is_mobile ? 'unset' : 50}]}>
                                  I started dining social because I was tired of going out to bars, cafes, networking events and never making any real connections. I want to create a space where you can grab dinner or brunch any day of the week and know that the people there will be excited to meet you. Read more about my story below.
                                </Text>
                              </View>
                            </View>
                          </View>
                          <View style={{width: is_mobile ? '100%' : '60%'}}>
                            <View style={{marginHorizontal: is_mobile ? 10 : 50, marginTop: 50}}>
                              <Image style={{height: is_mobile ? 250 : 400, width: '100%'}} resizeMode='cover' source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/AboutImage.jpeg'}} />
                              <Text style={{color: 'white', backgroundColor: 'black', padding: 3, fontFamily: 'Ysabeau', fontSize: 18, paddingLeft: 10}}>Lisa, Aleksandra & Jan at Frankie, Sept. '23</Text>
                            </View>
                          </View>
                          
                      </View>
                      <View style={{paddingTop: 70, paddingHorizontal: is_mobile ? 20 : 200, marginBottom: 0, flexDirection: 'column'}}>
                                <Text style={[style.dining_text, {marginBottom: 40}]}>
                                At 11 years old, I emigrated from Otwock, Poland to Brooklyn, NY. At 18, I went on to college to receive a Bachelor's from Cornell University with a double major in Computer Science and Engineering Physics. After my Bachelor's, I completed my Master’s in Applied & Engineering Physics with a focus on computer simulations (thank you fin aid and just paid off those loans 😅). My modus operandi in college was to learn what I was interested in, and so, naturally, I had no idea what I wanted to do after school.
                                </Text>
                                <Text style={[style.dining_text, {marginBottom: 40}]}>
                                My first job out of grad school was working for a big, chip-making company, which I ended up quitting after a year and a half. This was my longest salaried position. After quitting, I was introduced to the world of contracting and the rest was history. I started my own freelance LLC and never came back to a salaried position. Connect with me professionally on <TouchableOpacity onPress={()=>window.open('http://www.linkedin.com/pub/aleksandra-czajka/5/3a4/13b')}><Text style={global_style.normal_link}>LinkedIN</Text></TouchableOpacity>
                                </Text>
                                <Text style={[style.dining_text, {marginBottom: 40}]}>
                                I've been building web apps, mobile apps, desktop apps and all types of custom software, learning languages on the job and picking up per-job requirements on the fly for the past 15 years. Alongside my software freelance business, I've dabbled in one start-up venture or another over the past 10 years - it’s easy when you’re a software engineer. Most of my ideas (even though fabulous :-D) ended up flopping. 
                                </Text>
                                <Text style={[style.dining_text, {marginBottom: 40}]}>
                                I was working on one such floppy idea in Sept ‘22 when I broke up with a boyfriend, who lived in NYC. To be more social in Jersey City vs NYC, I stared the Jersey City Social meetup. For quite a few months, we’ve had different types of events based on our members’ interest. I quickly realized that small, cozy groups much better cater to getting to know each other and forming real bonds.
                                </Text>
                                <Text style={[style.dining_text, {marginBottom: 40}]}>
                                Huge crowds and loud environments, while popular, are not conducive to forming real, solid friendships. And so, after a few months of trying different formats, Dining Social was born in June of '23. Meeting so many people in a cozy setting since June has changed my life and perspective completely. Because of this, while we grow, our mission is to keep the personal and cozy nature of Dining Social a must in our events.
                                </Text>
                                <Text style={[style.dining_text, {marginBottom: 0}]}>
                                I do hope you can join us soon, and please do not hesitate to reach out at team@diningsocialnj.com. I'm always open to discussing partnerships and ideas.
                                </Text>
                      </View>
                    
                  </View>

                  <View style={{position: 'relative', justifyContent: 'center', alignItems: 'center', width: '80%', backgroundColor: '#1e1c1c', height: 300, textAlign: 'center', margin: 'auto', borderTopWidth: 1, borderTopColor: '#9d9b9b', marginBottom: 200, marginTop: 100}} >
                    <Text style={{color: 'white', fontFamily: 'Ysabeau', fontSize: 40, marginBottom: 10}}>Want to have a chat?</Text>
                    {/* <Text style={{color: 'white', fontFamily: 'Ysabeau', fontSize: 30, marginBottom: 30}}>We're open to chatting!</Text> */}
                    <TouchableOpacity onPress={this.props.toggleContact} ><Text style={[style.three_buttons_button, {width: 250}]}>Reach out.</Text></TouchableOpacity>
                  </View>
          </View>
        </View>
      </View>
    );
  }
}

function Mission(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();

    return <Mission_Base {...props} navigate={navigate} />
  } else {
    return <Mission_Base {...props} navigate={props.navigation.push} />
  }
}

export default Mission;
