import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform, Image, Picker, Dimensions } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { useNavigate } from 'react-router-dom';
import { Text, Input, Button } from '../../components';
import UserController from '../../controllers/userController';
import { style } from './style';
import { global_style } from '../../../global_style'

class UserTransactions_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: props.user
    }
  }

  async componentDidMount() {
    track('Transactions Page');
    let transactions = await UserController.getTransactions();

    this.setState({
      transactions: transactions.transactions,
      mounted: true
    })
  }

  render() {

    let { mounted, transactions } = this.state;

    let transactions_element = <View>
      <View style={[style.dashboard_panel, style.profile_panel, {paddingBottom: 100}]}>
        <Text style={style.section_title}>Your Transactions</Text>
          {transactions && transactions.length ? 
            <View style={style.dinners_wrapper}>
              <View style={style.dinners_header}>
                <Text style={style.dinner_header_text}>Date</Text>
                <Text style={style.dinner_header_text}>Name</Text>
                <Text style={style.dinner_header_text}>Price</Text>
                <Text style={style.dinner_header_text}>Receipt</Text>
              </View>
              {transactions.map((transaction, i) => {

                let date = (new Date(transaction.date)).toLocaleString();

                return <View style={style.dinner_row}>
                  <Text style={style.dinner_content}>{date}</Text>
                  <Text style={style.dinner_content}>{transaction.name}</Text>
                  <Text style={style.dinner_content}>${transaction.price / 100}</Text>
                  <TouchableOpacity onPress={()=>{window.open(transaction.receipt)}}>
                    <Text style={[style.dinner_content, style.link]}>Receipt <Text style={style.out_square}>&#x2197;</Text></Text>
                  </TouchableOpacity>
                </View>
              })}
            </View> :
          <View style={style.a_row}>
            <Text>You currently do not have any transactions (Your subscription is shown separately in the subscription tab).</Text>
          </View>}
        </View>
      </View>;

    return mounted ? <>{transactions_element}</> : <View style={{flex: 1}}><Progress.CircleSnail size={60} backgroundColor="white" fill='white' color={'black' } /></View>;
  }

}

function UserTransactions(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <UserTransactions_Base {...props} navigate={navigate} />
  } else {
    return  <UserTransactions_Base {...props} navigate={props.navigation.push}  />
  }
}

export default UserTransactions
