import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, Platform, Dimensions, TouchableOpacity } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { style } from './style';
import { Text, Button } from '../../components';
import { DemoEvents, EditProfile, MemberPortal, AdminPortal, YourEvents, YourMessages } from '../../containers';
import Calendar from '../Calendar';
import UserController from '../../controllers/userController';
import PaymentController from '../../controllers/paymentController';
import Carousel from 'react-native-reanimated-carousel';
import _ from 'lodash';
import Accordion from 'react-native-collapsible/Accordion';
import { Analytics, PageHit } from 'expo-analytics';
const analytics = new Analytics('G-YCC5QPC8BJ');
const home_image = require('../../../assets/jc_social_main.jpeg');
import { init, track } from '@amplitude/analytics-react-native';
import { global_style } from '../../../global_style';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { MaterialIcons, FontAwesome } from '@expo/vector-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { UserSubscription, UserTransactions, UserNotifications, Invites, Event } from '../../containers'

let width = Dimensions.get('window').width;

class Dashboard_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      display_section: 'phone_input',
      phone_number: '',
      phone_number_valid: false,
      validation_code: '',
      email: '',
      password: '',
      tos_accepted: false,
      calendar_index: 0,
      activeSections: [],
      infoSections: [],
      url_tab: this.props.tab,
      to_user: props.to_user,
      is_mobile: width < 500,
      tab: this.props.tab ? this.props.tab : 'calendar'
    }

    this.calendarRef = React.createRef();

    this.logout = this.logout.bind(this);
    this.showDay = this.showDay.bind(this);

    this.goTo = this.goTo.bind(this);
    this.openCreate = this.openCreate.bind(this);
    this.openInParent = this.openInParent.bind(this);

    this.toggleState = this.toggleState.bind(this);
  }

  async componentDidMount() {

    analytics.hit(new PageHit('Dashboard'))
      .then(() => {})
      .catch(e => console.log("analytics error", e.message));

    track('Dashboard Page');

    let user = await UserController.getUser(true);

    console.log('user', user);

    let subscription = await UserController.getUserSubscriptionStatic();

    let host_account = user ? user.host_account : null;

    let is_member = subscription && subscription.status ? subscription.status === 'trialing' || subscription.status === 'active' : false;

    is_member = is_member || !!host_account;

    let points = user && user.points ? user.points : [];

    this.setState({
      is_member,
      profile: user.profile ? user.profile : {},
      points,
      user: user.user,
      mounted: true
    }, async () => {
      let { is_member } = await UserController.getUserSubscriptionLive(user);

      this.setState({
        is_member
      });
    })
  }

  componentDidUpdate() {
    console.log('getting here', this.props);
    if(this.props.tab !== this.state.url_tab) {
      console.log('gettin ghere')
      let tab = this.props.tab;
      tab = tab ? tab : 'calendar'
      this.setState({
        url_tab: this.props.tab,
        tab
      })
    }
  }

  goTo(link) {
    this.props.navigate(link)
  }

  showDay() {

    if(this.calendarRef) {
      this.calendarRef.scrollIntoView();
    }

    this.setState({
      selected_date: new Date('8/10/2023')
    });
  }

  _renderSectionTitle = (section) => {
    return <View></View>;
  };

  _renderHeader = (section) => {
    return (
      <View style={style.category_header}>
        <Text style={style.category_header_text}>{section.title}</Text>
      </View>
    );
  };

  _renderContent = (section) => {
    return (
      <View>
        {section.content}
      </View>
    );
  };

  _updateSections = (activeSections) => {
    this.setState({ activeSections });
  };

  _renderSectionTitleInfo = (section) => {
    return <View></View>;
  };

  _renderHeaderInfo = (section) => {
    return (
      <View style={style.category_info_header}>
        <Text style={style.category_info_header_text}>{section.title}</Text>
      </View>
    );
  };

  _renderContentInfo = (section) => {
    return (
      <View>
        {section.content}
      </View>
    );
  };

  _updateSectionsInfo = (infoSections) => {
    this.setState({ infoSections });
  }

  async logout() {
    await UserController.logout();
    window.location.replace('/')
  }

  openCreate() {
    let { is_member } = this.state;

    if(!is_member) {
      this.openInParent(<View style={global_style.center_content}>
        <Text style={[global_style.medium_description, {marginBottom: 20, paddingTop: 0}]}>You're not a member just yet. As a member, you'll be able to invite others to coffee / happy hour / dinner.  </Text>
        <Button title={'View Our Plans'}
            loading={false}
            style={{
              fontFamily: 'Ysabeau',
              backgroundColor: 'black',
              alignSelf: 'center',
              borderRadius: 3,
              paddingTop: 5,
              paddingBottom: 7,
              paddingHorizontal: 5, marginBottom: 10}}

            onPress={()=>{window.location.replace('/dashboard/subscription')}} /> 
      </View>, "is_create_notify_open", 400, 300)
    } else {
      window.location.replace('/create_invite')
    }
  }

  toggleState(name) {
    console.log('toggling', name, this.state[name])

    if(this.state[name]) {
      this.props.putInParent(null)
    }

    this.setState({
      [name]: !this.state[name]
    })
  }

  openInParent(content, state_variable, width, height) {
    console.log('drawing in parent again', state_variable, this.state[state_variable]);
    this.props.putInParent(!this.state[state_variable] ? 
      <TouchableOpacity style={{zIndex: 10000000, position: 'fixed', width: '100vw', height: '100vh'}} onPress={()=>this.toggleState(state_variable)}>
      <>
        <TouchableOpacity onPress={()=>this.toggleState(state_variable)} style={global_style.modal_overlay}></TouchableOpacity>
        <View style={{position: 'fixed', width: '100vw', height: '100vh', zIndex: 2000000}}>
          <View style={[global_style.modal_content_mobile, {display: 'block', width, height, left: `calc(50% - ${width / 2}px)`, top: 100}]}>
              <Text style={[global_style.modal_close_x, global_style.padding_20]}>x</Text>
              <View style={{flex: 1, height: 'calc(80vh - 20px)' }}>
                <View style={[global_style.padding_20]}>{content}</View>
            </View>
          </View>
        </View>
      </>

      </TouchableOpacity> : null);

    this.setState({
      [state_variable]: !this.state[state_variable]
    });
  }

  render() {
    let { mounted, tab, mobile_nav_open, points, user, profile, is_mobile } = this.state;

    let user_id = user ? user.user_id : null;

    let nav_open = mobile_nav_open || !is_mobile;

    let SECTIONS = []

    let app_mode = is_mobile
    
    if(mounted) {

      let subscription_element = <UserSubscription user={user} />

      let availability_element = <View style={[style.dashboard_panel]}>
        {/* <Button title={'Create Invite'}
            loading={false}

            style={{
              backgroundColor: '#e32652',
              alignSelf: 'center',
              borderRadius: 3,
              paddingTop: 5,
              paddingBottom: 7,
              paddingHorizontal: 5, marginBottom: 10}}

            onPress={this.openCreate} /> */}

        {<Invites />}
        <View style={{marginTop: 20}}>
        {/* {app_mode ? <>
          <Text style={[{fontFamily: 'Ysabeau', fontSize: 20, textTransform: 'uppercase', textAlign: 'center', paddingBottom: 40} ]}>Social Dining Calendar</Text></> :
          <>
          <Text style={[{fontFamily: 'Ysabeau', fontSize: 34, textTransform: 'uppercase', paddingLeft: 40, textAlign: 'center', paddingBottom: 40} ]}>Social Dining Calendar</Text></>} */}
          {/* <DemoEvents putInParent={this.props.putInParent} /> */}
          <View style={{position: 'relative'}} >
            <Calendar putInParent={this.props.putInParent} isLoggedIn={true} />
          </View>
        </View>

          </View>

      let profile_element = <EditProfile putInParent={this.props.putInParent} profile={profile} />

      let admin_element = <AdminPortal putInParent={this.props.putInParent} />

      let dinners_element = <YourEvents putInParent={this.props.putInParent} user={user} />
      let your_messages = <YourMessages putInParent={this.props.putInParent} user={user} />

      let transactions_element = <UserTransactions />

      let notifications_element = <UserNotifications />

      SECTIONS = [
        {
          left_tab_title: 'Calendar',
          title: 'Calendar',
          content: availability_element,
          link: 'calendar'
        },
        /* {
          left_tab_title: 'Your RSVPs',
          title: 'Your RSVPs',
          content: dinners_element,
          link: 'my_events'
        }, */
        /* {
          left_tab_title: 'Your Messages',
          title: 'Your Messages',
          content: your_messages,
          link: 'messages'
        }, */
        /* {
          left_tab_title: 'Member Portal',
          title: 'Member Portal',
          content: portal_element,
          link: 'portal'
        }, */
        {
          left_tab_title: 'Profile',
          title: 'Your Profile',
          content: <View>
            {profile_element}
          </View>,
          link: 'profile'
        },/* 
        {
          left_tab_title: 'Subscription',
          title: 'Your Subscription',
          content: subscription_element,
          link: 'subscription'
        }, */
        {
          left_tab_title: 'Transactions',
          title: 'Your Transactions',
          content: transactions_element,
          link: 'transactions'
        },
        {
          left_tab_title: 'Notifications',
          title: 'Notifications',
          content: notifications_element,
          link: 'notifications'
        },
        /* {
          left_tab_title: 'Hosting',
          title: 'Hosting',
          content: hosting_element,
          link: 'hosting'
        } */
      ];

      if(user_id === 'cc467f19-6954-4151-ba3c-c45c8563511c') {
        SECTIONS.push({
          left_tab_title: 'Admin',
          title: 'Admin',
          content: admin_element,
          link: 'admin'
        })
      }
    }

    return (mounted ?
      <View style={style.page_wrapper}>
         {/* <button onClick={()=>PaymentController.clickMe()}>click me</button> */}
        <View style={style.main_dash_wrapper}>
          <TouchableOpacity onPress={()=>{this.setState({mobile_nav_open: !mobile_nav_open})}} style={style.collapsable_burger}>
            {mobile_nav_open ?
              <View style={style.burger_close}>
                <Text style={style.burger_x}>X</Text>
              </View> :
              <View style={style.burger}>
                <Text style={style.burger_text}>Menu</Text>
                {/* <FontAwesomeIcon icon={faEllipsisV} size={40} color="light-grey" /> */}
              </View>}
          </TouchableOpacity>
          {nav_open ?
          <View style={style.left_nav}>
            {SECTIONS.map((a, i) =>{
              return <TouchableOpacity onPress={()=>{window.location.replace(`/dashboard/${a.link}`)}}><Text style={[style.left_button, tab === a.link ? style.left_button_selected : {}]}>{a.left_tab_title}</Text></TouchableOpacity>
            })}
            <TouchableOpacity onPress={this.logout}><Text style={[style.left_button]}>Log out</Text></TouchableOpacity>
          </View>: null}
          <View style={{flex: 1}}>
            {SECTIONS.map((this_tab, i)=>{
              return this_tab.link === tab ? this_tab.content : null
            })}
          </View>
        {/* <Accordion
            sections={SECTIONS}
            activeSections={this.state.activeSections}
            renderSectionTitle={this._renderSectionTitle}
            renderHeader={this._renderHeader}
            renderContent={this._renderContent}
            onChange={this._updateSections}
          /> */}
          
        </View>
      </View> : null
    );
  }
}

function DashboardScreen(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    let { tab, to_user } = useParams();
    return  <Dashboard_Base {...props} tab={tab} to_user={to_user} navigate={navigate} />
  } else {
    return  <Dashboard_Base {...props} navigate={props.navigation.push}  />
  }
}

export default DashboardScreen;
