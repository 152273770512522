import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform, Dimensions, Image, Animated } from 'react-native';
import { Video } from 'expo-av';
import { useNavigate } from 'react-router-dom';
import { Text, Button } from '../../components';
import { Modal, Profile, FAQ } from '../../containers';
import { style } from './style';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import UserController from '../../controllers/userController';
import AuthController from '../../controllers/authController';
import Utilities from '../../utilities';
import { global_style } from '../../../global_style';
import PaymentController from '../../controllers/paymentController';
import {
  LoginRegisterScreen,
  RegisterScreen,
} from '../../screens';
import { FontAwesome5 } from '@expo/vector-icons';
import { init, track } from '@amplitude/analytics-react-native';

let width = Dimensions.get('window').width;
//library.add(faInstagram);

class Event_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      event_id: props.event_id,
      mounted: false,
      is_mobile: width < 500,
      currentIndex: 0,
      photos: [

        {uri: 'https://diningsocial-assets.s3.amazonaws.com/virtual_1.png', order: 1},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/v_2.png', order: 1},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/v_3.png', order: 1},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/h_2.png', order: 1},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/h_1.png', order: 1},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/h_5.png', order: 1},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/h_4.png', order: 1},
      ],
      photos_amelia: [
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_two.MOV', type: 'movie', order: 0}, 
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_one.png', order: 1}, 
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_one.mov', type: 'movie', order: 2}, 
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_two.png', order: 3}, 
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_three.JPG', order: 4},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_last.JPG', order: 6}]
    }

    this.modal_ref = React.createRef();
    this.translateX = new Animated.Value(0);

    this.drawEvent = this.drawEvent.bind(this);
    this.openRSVPModal = this.openRSVPModal.bind(this);
    this.getPriceNote = this.getPriceNote.bind(this);
    this.rsvp = this.rsvp.bind(this);
    this.openRSVPedModal = this.openRSVPedModal.bind(this);
  }

  async componentDidMount() {

    track(`Event Page - August 25th`)

    let { event_id, user } = this.state;

    let token = await localStorage.getItem('token');
    let isLoggedIn = !!token;
    let is_member = false;

    if(isLoggedIn && !user) {
      user = await UserController.getUser(true);
      user = user ? user.user : null;
      ({ is_member } = await UserController.getUserSubscriptionLive(user))
    }

    let user_id = user ? user.id : null;

    let rsvps, event_data, hosts;
    ({rsvps} = await UserController.getDateRsvpsForEvent({event_id}));
    event_data = await AuthController.getEventAndPlace({event_id});
    hosts = await AuthController.getHosts();

    console.log('event_data', event_data)

    let event = event_data.event_data;
    event = {
      ...event,
      event_id
    }
  


    this.setState({
      is_member,
      isLoggedIn,
      hosts,
      event,
      place: event_data.place_data,
      rsvps,
      user_id,
      mounted: true
    })
  }

  async rsvp() {
    try {
      track(`RSVP clicked to purchase`)
    this.setState({rsvp_loading: true}, async () => {
      let { event_id } = this.state;

      let data = {
        event_id,
        cancel_url: `virtual/${event_id}`
      }
  
      let response = await PaymentController.createEventSession(data);
  
      if(response.url) {
        window.location.replace(response.url);
      } else {
        window.location.reload();
      }
    })
    } catch(err) {
      console.log('errrr', err)
    }
  }

  getPriceNote(event) {
    let {price, price_member, early_bird, early_bird_member_price, early_bird_price, early_bird_deadline} = event;

    let { is_member } = this.state;
    let early_bird_panel = null;
    let normal_panel = null;

    let with_membership_panel = null;
    let the_price;

    let fontFamily = 'Founders Grotesk,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif!important'

    let disable_refunds_panel = false;
    if(is_member && !price_member) {
      disable_refunds_panel = true;
      normal_panel = <View style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
      <View>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textDecorationLine: 'strike-through' }}>Member Pricing:</Text>
        </View>
        
        <View style={{flexDirection: 'column', alignItems: 'flex-end'}}>
          <Text style={{ fontFamily, fontSize: 20, }}>${price_member}.00</Text>
        </View>
      
      </View>
    } else {
      if(early_bird) {
        the_price = is_member ? early_bird_member_price : early_bird_price;
        let normal_price = is_member ? price_member : price;

        early_bird_panel = <View style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom: 10}}>
          <View>
          <Text style={{fontFamily: 'Ysabeau', fontSize: 20 }}>Early Bird Pricing:</Text>
          </View>
          
          <View style={{flexDirection: 'column', alignItems: 'flex-end'}}>
            <Text style={{ fontSize: 20, fontFamily }}>${the_price}.00</Text>
          </View>
        
        </View>

        normal_panel = <View style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
        <View>
          <Text style={{fontFamily: 'Ysabeau', fontSize: 20, opacity: .4, textDecorationLine: 'strike-through' }}>Normal Pricing:</Text>
          </View>
          
          <View style={{flexDirection: 'column', alignItems: 'flex-end'}}>
            <Text style={{ fontFamily, fontSize: 20, opacity: .4, }}>${normal_price}.00</Text>
          </View>
        
        </View>


        with_membership_panel = <View style={{alignItems: 'flex-end', marginTop: 10}}>{!is_member ?
          <TouchableOpacity style={{borderBottomWidth: 1,}} onPress={()=>{window.location.replace('/dashboard/subscription')}}>
            <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}><Text style={{fontFamily: 'Ysabeau', fontSize: 16 }}>with membership</Text><Text style={{color: '#1fb411', marginLeft: 5, fontFamily, fontSize: 20}}>${early_bird_member_price}.00</Text></View>
          </TouchableOpacity> : null}</View>
      } else {
        the_price = is_member ? price_member : price;

        normal_panel = <View style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
        <View>
          <Text style={{fontFamily: 'Ysabeau', fontSize: 20 }}>Subtotal:</Text>
          </View>
          
          <View style={{flexDirection: 'column', alignItems: 'flex-end'}}>
            <Text style={{fontFamily, fontSize: 20 }}>${the_price}.00</Text>
          </View>
        
        </View>

        with_membership_panel = <View style={{alignItems: 'flex-end', marginTop: 10}}>{!is_member ?
          <TouchableOpacity style={{borderBottomWidth: 1,}} onPress={()=>{window.location.replace('/dashboard/subscription')}}>
            <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}><Text style={{fontFamily: 'Ysabeau', fontSize: 16 }}>with membership</Text><Text style={{color: '#1fb411', marginLeft: 5, fontFamily, fontSize: 20}}>${price_member}.00</Text></View>
          </TouchableOpacity> : null}</View>
      }
    }


    return <View><View style={{flexDirection: 'column', width: '100%'}}>
      {early_bird_panel}
      {normal_panel}
      {with_membership_panel}
      <View style={{height: 20, width: '100%'}}></View>
      {early_bird && !(is_member && !price_member) ?
              <Text style={{fontFamily: 'Ysabeau', fontSize: 16, marginTop: 0, textAlign: 'left' }}>* Early bird pricing ends {early_bird_deadline}</Text>
        :null}
      {disable_refunds_panel ? <Text style={{fontFamily: 'Ysabeau', fontSize: 16, marginTop: 20, textAlign: 'left' }}>* Please make sure you can make it. Even though most events are free for our members, cancelations prove a hassle for our hosts.</Text> :
      <Text style={{fontFamily: 'Ysabeau', fontSize: 16, marginTop: 0, textAlign: 'left' }}>* No refunds provided unless the event is canceled or moved.</Text>}

      </View>
      <View style={{marginTop: 30, alignItems: 'center'}}>
        <Button 
          title={<Text style={{fontFamily: 'Ysabeau', fontSize: 20, color: 'white'}}>{disable_refunds_panel ? 'RSVP' : `Checkout for $${the_price}.00`}</Text>}
          disable_imediately={true}
          disable
              style={{
                backgroundColor: '#e32652',
                borderRadius: 3,
                paddingTop: 5,
                paddingBottom: 7,
                paddingHorizontal: 5, marginBottom: 10}}

              onPress={this.openRSVPModal} />
      </View>
    </View>
  }

  openRSVPModal() {
    track(`RSVP Modal Opened`)

    let { isLoggedIn, is_reg } = this.state;

    if(!isLoggedIn) {

      const toggleReg = () => {
        this.setState({
            is_reg: !this.state.is_reg
        }, this.openRSVPModal)
      }

      let login_reg_content = is_reg ? <RegisterScreen onLoginClick={toggleReg} putInParent={this.props.putInParent} /> : <LoginRegisterScreen onRegisterClick={toggleReg} putInParent={this.props.putInParent} />

      this.modal_ref.setContent(login_reg_content, 500, '90vh', null, false, null, null, null);

    } else {
      this.rsvp();
    }
  }

  async openRSVPedModal() {
    let { profile_attendee } = this.state;

    let is_mobile = width < 500;
    let event_component;

    event_component = <Profile attendee={profile_attendee} />

    this.modal_ref.setContent(event_component, 600, '90vh', null, false, null)
  }

  handleNextPress = () => {
    const { currentIndex, photos } = this.state;

    let width = Dimensions.get('window').width > 1273 ? 1273 : Dimensions.get('window').width;

    const nextIndex = currentIndex + 1 < photos.length ? currentIndex + 1 : 0;

    console.log('nextIndex', nextIndex)

    Animated.timing(this.translateX, {
      toValue: -width * nextIndex,
      duration: 300,
      useNativeDriver: true,
    }).start();
    this.setState({ currentIndex: nextIndex });
  };

  handlePrevPress = () => {
    const { currentIndex } = this.state;

    let width = Dimensions.get('window').width > 1273 ? 1273 : Dimensions.get('window').width;

    const prevIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : photos.length - 1;
    Animated.timing(this.translateX, {
      toValue: -width * prevIndex,
      duration: 300,
      useNativeDriver: true,
    }).start();
    this.setState({ currentIndex: prevIndex });
  };

  pricingElement() {
    let { event, rsvps, user_id, is_member, rsvp_loading } = this.state;
    let { rsvp_limit, similar_event_url } = event;
    let rsvped = rsvps ? rsvps.filter(a=>a.user_id === user_id) : null;
    rsvped = rsvped && rsvped.length ? true : false;

    let rsvps_closed = rsvps && rsvps.length >= rsvp_limit;

    return <View style={{padding: 40, marginBottom: 40, borderRadius: 30, boxShadow: '4px 4px 10px 0px #ccc'}}>{rsvped || rsvps_closed ? 
      <View>
        {rsvped ?
        <Text style={{fontFamily: 'Comfortaa', color: 'green', fontSize: 20, backgroundColor: 'white', textAlign: 'center', borderRadius: 10, padding: 10}}>You're going!</Text> : null}
        {rsvps_closed ? <View>
          <Text style={{fontFamily: 'Comfortaa', color: 'red', fontSize: 20, backgroundColor: 'white', textAlign: 'center', borderRadius: 10, padding: 10}}>RSVPs are closed</Text>
         {similar_event_url ? <TouchableOpacity onPress={()=>{window.location.replace(similar_event_url)}}><Text style={[global_style.normal_link, {fontSize: 18, alignSelf: 'center', color: '#1c95ad', marginTop: 10}]}>Check Out This Smiliar Event</Text></TouchableOpacity> : null}
          </View> : null}
      </View> :
      <View>
        {this.getPriceNote(event)}

        </View>}
      </View>
  }

  drawEvent() {
    let { place, event, rsvps, hosts, user_id, rsvp_loading, photos, currentIndex } = this.state;

    let prelim_attendees = rsvps;

    let the_dinner = event;

    console.log('rsvps', rsvps)

    let rsvped = rsvps ? rsvps.filter(a=>a.user_id === user_id) : null;
    rsvped = rsvped && rsvped.length ? true : false;

    let { restaurant_name, link, main_image, address, directions_link } = place;
    let { time, date, early_bird_price, price_member } = event;

    let displayTime = Utilities.getEventTime(time);
    let displayDate = Utilities.getEventDate(date);
    
    let host_obj;
    if(event.hosted && event.host) {
      console.log('hosts', hosts)
      host_obj = hosts ? hosts.filter(host => {return host.user_id === event.host }) : null;
      console.log('host_obj', host_obj)
      host_obj = JSON.parse(host_obj[0].profile);
    }

    let chosen_style = {};

    let is_mobile = width < 700;

    let current_rsvps = prelim_attendees.length;

    let rsvp_needed = event.rsvp_needed ? event.rsvp_needed : (event.hosted ? 4 : 3);

    let needs_more = event.event_id && prelim_attendees ? ( rsvp_needed - current_rsvps ) : null;

    let needed_rsvps = needs_more;

    console.log('needed_rsvps', needed_rsvps)

    let rsvps_open = event.hosted ? event.rsvp_limit_hosted - current_rsvps : event.rsvp_limit_not_hosted - current_rsvps;

    let agenda = <Text style={{fontFamily: 'Comfortaa', fontSize: 16, lineHeight: 26}}>
      <Text style={{fontFamily: 'Comfortaa', fontSize: 22, borderColor: '#9d9b9b', borderBottomWidth: 1}}>Pre-Dinner</Text>
      <br/><br/>
      Please be sure to be ready with the following ingredients and preparation...
      <ol>
        <li>
          3 medium potatoes, peeled and finely grated
        </li>
        <li>
          1/2 medium onion, finely grated
        </li>
        <li>
          1 large egg
        </li>
        <li>
          Have handy: salt, pepper, flour
        </li>
        <li>
          Any favorite garnish: sour cream, applesauce, etc. (I personally eat them plain.)
        </li>
      </ol>
      <br/><br/>
      <Text style={{fontFamily: 'Comfortaa', fontSize: 22, borderColor: '#9d9b9b', borderBottomWidth: 1}}>During Dinner</Text><br/><br/>Here’s our schedule. Throughout our call, private rooms will be available, allowing you to have a one-on-one chat with anyone you resonate with.<br/><br/>
    
    <View style={{flexDirection: 'row', marginBottom: 20}}><View style={{width: is_mobile ? 100 : 101}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22}}>6:30 PM</Text></View><View style={{width: is_mobile ? 240 : '400px'}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22, marginBottom: 10}}> **Meet & Greet**</Text>Join our zoom channel as we go through relaxed introductions. </View></View>

    <View style={{flexDirection: 'row', marginBottom: 20}}><View style={{width: is_mobile ? 100 : 101}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22}}>6:45 PM</Text></View><View style={{width: is_mobile ? 240 : '400px'}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22, marginBottom: 10}}> **Make our Side-Dish**</Text>After everyone has joined us, and we've all met, our friendly host will take us through making our side dish and explain a bit about the history of the dish. Don't be shy, ask questions. This is what we're here for!</View></View>

    <View style={{flexDirection: 'row', marginBottom: 20}}><View style={{width: is_mobile ? 100 : 101}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22}}>7:15 PM</Text></View><View style={{width: is_mobile ? 240 : '400px'}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22, marginBottom: 10}}> **Showcase & Dinner**</Text>Let's show off our creations and chat over dinner. </View></View>

    <br/><br/>
    <Text style={{fontFamily: 'Comfortaa', fontSize: 22, borderColor: '#9d9b9b', borderBottomWidth: 1}}>Post Dinner</Text><br/><br/>After our zoom call, DiningSocial will be creating a compilation of all our dishes! Send over any pictures or videos to team@diningsocialnj.com or through <a target="_blank" href="https://www.instagram.com/diningsocialnj">instagram</a>. Follow us so we can tag you!
    




    <View style={{marginTop: 60, textAlign: 'center', alignSelf: 'center', display: 'flex', fontSize: 30, fontFamily: 'cursive', letterSpacing: 5, marginBottom: 40}}>See you there!</View>

    </Text>


    let description = <Text style={{fontFamily: 'Comfortaa', fontSize: 16, lineHeight: 26}}><Text style={{fontFamily: 'Ysabeau', fontSize: 26}}>Join us for our FIRST virtual dinner.</Text>
 
    <br/><br/>
    We're launching a virtual series where locals gather over dinner, guided by our friendly hosts to prepare a themed side dish. As we cook, we'll mingle, introduce ourselves, and enjoy some fun icebreakers.
    <br/><br/>
    <b>DO I HAVE TO JOIN IN ON THE COOKING? </b>You're always welcome to come, spectate and be part of the social fun without cooking.
    
    <br/><br/>
    Check out more FAQs below!
    <br/><br/><br/><br/>
    <div>
      <b style={{textTransform: 'uppercase'}}>We're looking for Hosts!</b><br/>
      
    </div>
    <View style={{flexDirection: 'row', paddingTop: 20}}>
      <View style={{flex: 1, marginBottom: is_mobile ? 10 : 'unset'}}>
        <Image source={{ uri: photos[1].uri }} style={{ width: is_mobile ? 160 : 200, height: 200, margin: 'auto'}} resizeMode='contain' />
      </View>
      <View style={{flex: 1}}>
        <b>THIS IS A PAID GIG!</b>Does hosting a social cooking event sound exciting? You don't have to be a great cook, just have a passion for connecting people and helping them learn.
      </View>
    </View>
    We're open to any and all types of foods and even mixologists. Reach out to us for a chat.

    </Text>

    const addToCalendar = () => {

      let day_date, month, year;
      let tokens = date.split('/');

      day_date = tokens[1];
      if(day_date.length === 1) {
          day_date = `0${day_date}`
      }

      month = tokens[0];
      if(month.length === 1) {
          month = `0${month}`
      }

      year = tokens[2];

      
      let hours = Math.floor(time);
      let minutes = Math.round((time - hours) * 60);

      if(minutes.toString().length === 1) {
          minutes = `0${minutes}`
      }

      let end_time = time + 1.5;

      let end_hours = Math.floor(end_time);
      let end_minutes = Math.round((end_time - end_hours) * 60);

      if(end_minutes.toString().length === 1) {
          end_minutes = `0${end_minutes}`
      }

      let dinner_begin_time = `${hours}${minutes}00`;
      let dinner_end_time = `${end_hours}${end_minutes}00`;

      let dates_link = `${year}${month}${day_date}T${dinner_begin_time}/${year}${month}${day_date}T${dinner_end_time}`;

      let address = `Amelia's Bistro, 187 Warren St, Jersey City, NJ 07302, USA`;

      let calendar_link = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(`DiningSocial Dinner at ${restaurant_name}`)}&location=${address}&dates=${dates_link}&details=${encodeURIComponent(`DiningSocial Dinner at ${restaurant_name} - Meet locals over networking and conversation - DiningSocialNJ.com`)}&trp=false`;

      window.open(calendar_link)
    }

    photos.sort((a, b) => { return a.order < b.order ? -1 : 1 });

    console.log('photos', photos)

    let img_size = 50;

    let host_element = <View style={{marginBottom: 20}}>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 20, marginBottom: 10}}>Your Host</Text>
        <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>

          <TouchableOpacity onPress={()=>{this.setState({profile_attendee: {profile: host_obj}}, this.openRSVPedModal)}} style={{flexDirection: 'column', width: img_size + 10}}>
            <View style={{width: img_size, height: img_size, borderRadius: 100, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', marginRight: 10, marginBottom: 10}}><Image style={{ width: img_size,
            height: img_size,
            borderRadius: 100}} source={{uri: host_obj.photo_url ? host_obj.photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} /></View>
            </TouchableOpacity>
        </View>
      </View>
    
    //1273
    let vid_width = Dimensions.get('window').width > 1273 ? 1273 : Dimensions.get('window').width;

    let image_width = Dimensions.get('window').width > 1000 ? 1000 : Dimensions.get('window').width;

    let pricing_panel = <View style={{paddingLeft: is_mobile ? 0 : 50}}>
      {this.pricingElement()}
      {host_element}
      {rsvps && rsvps.length ?
      <View style={{marginBottom: 20}}>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 20, marginBottom: 10}}>RSVPs</Text>
        <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
        {rsvps.map(event_rsvp=>{
            let { profile } = event_rsvp;
            profile = profile  ? profile : `{"name": "Member", "user_id": null, "photo_url": "https://diningsocial-assets.s3.amazonaws.com/empty_profile.png"}`
            let { photo_url, name, user_id } = JSON.parse(profile);
          return <TouchableOpacity onPress={()=>{this.setState({profile_attendee: {profile}}, this.openRSVPedModal)}} style={{flexDirection: 'column', width: img_size + 10}}>
            <View style={{width: img_size, height: img_size, borderRadius: 100, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', marginRight: 10, marginBottom: 10}}><Image style={{ width: img_size,
            height: img_size,
            borderRadius: 100}} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} /></View>
            </TouchableOpacity>
            
        })}
        </View>
      </View> : null}
      </View>

    return <View style={{width: vid_width, position: 'relative', margin: 'auto'}}>
{/*         <Image style={{width: '100%', height: 400, borderRadius: 13, borderBottomLeftRadius: 0, borderBottomRightRadius: 0}} resizeMode='cover' source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/social_people.png'}} /> */}
        <View style={{width: vid_width}}>
          {/* <Image style={{width: '100%', height: 500}} source={{ uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_one.png' }} />
          <Image style={{width: '100%', height: 500}} source={{ uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_one.png' }} /> */}

          <View style={{flex: 1, width: image_width, overflow: 'hidden', margin: 'auto', marginTop: is_mobile ? 20 : 'unset'}}>
            <Animated.View style={[{flexDirection: 'row',
                  width: vid_width * photos.length
              }, { transform: [{ translateX: this.translateX }] }]}>
                {photos.map((photo, index) => {
                  return photo.type === 'movie' ?
                      <Video
                        source={{ uri: photo.uri }}
                        style={{width: vid_width, height: (is_mobile ? 500 : 500)}}
                        videoStyle={{position: 'relative'}}
                        useNativeControls={true}
                        spee
                        shouldPlay={currentIndex === index}
                        resizeMode="cover"
                        rate={.7}
                        volume={0}
                        isMuted={true}
                      />
                  :
                  <Image key={index} source={{ uri: is_mobile ? photos[2].uri : photo.uri }} style={{ width: image_width, height: (is_mobile ? 500 : 500)}} resizeMode='contain' />
                })}
              
            </Animated.View>
            {/* <View style={{flexDirection: 'row', margin: 'auto', justifyContent: 'space-between', marginTop: 10, width: 120, position: 'absolute', bottom: 80, left: 'calc(50% - 60px)'}}>
              <Button style={{backgroundColor: '#e32652', padding: 0, margin: 0, width: 28, height: 29}} title={<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="caret-square-left" size={34} color="white" />}
              loading={false} onPress={this.handlePrevPress} />
              <Button style={{backgroundColor: '#e32652', padding: 0, margin: 0, width: 28, height: 29}} title={<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="caret-square-right" size={34} color="white" />}
              loading={false} onPress={this.handleNextPress} />
            </View>
            <View style={{width: vid_width}}>
              <Text style={{fontFamily: 'Ysabeau', fontSize: 20, color: 'white', backgroundColor: 'black', padding: 5, paddingBottom: 10, paddingRight: 10, textAlign: 'right'}}>Amelia's Social Dinner - June 1st, 2024</Text>
            </View> */}
            
          </View>
        </View>

        <View style={{padding: is_mobile ? 20 : 0}}>

        <View style={{width: '100%', marginTop: 50, textAlign: 'center', marginBottom: 40 }}>
          <Text style={{fontFamily: 'Ysabeau', fontSize: 28, marginBottom: 30}}>Join our FIRST...</Text>
          <Text style={{fontFamily: 'Ysabeau', fontSize: is_mobile ? 40 : 60, color: 'black'}}>{event.name}</Text>
          <View style={{}}>
            <Text style={{fontFamily: 'Ysabeau', fontSize: is_mobile ? 40 : 40, color: 'black'}}>Today's Theme: <Text style={{"-webkit-text-stroke-width": 1, "-webkit-text-stroke-color": "red", color: 'white'}}>Polish Food</Text></Text>
            <TouchableOpacity onPress={()=>{window.open('https://www.thespruceeats.com/polish-potato-pancakes-recipe-1137080')}}><Text style={{fontFamily: 'Ysabeau', fontSize: 24, color: 'black'}}>Dish: <Text style={{fontFamily: 'Ysabeau', fontSize: 24, color: 'brown', textDecorationLine: 'underline'}}>{event.dish ? event.dish : 'TBD'}</Text></Text></TouchableOpacity>
          </View>
          <View style={{ margin: 'auto', marginTop: is_mobile ? 20 : 20, marginBottom: 40 }}>
            {/* <Text style={{fontFamily: 'Ysabeau', fontSize: 40}}>RSVPs will open soon</Text> */}
          </View>
        </View>

            <View style={{flexDirection: is_mobile ? 'column' : 'row', borderColor: '#9d9b9b', borderTopWidth: is_mobile ? 0 : 1, borderBottomWidth: is_mobile ? 0 : 1, paddingHorizontal: is_mobile ? 0 : 100}}>
              <View style={{ flex: is_mobile ? 'unset' : 1}}>
                <View style={{backgroundColor: '#272c44', padding: 20, paddingBottom: 40, marginBottom: 40, width: is_mobile ? '100%' : 'unset', borderBottomWidth: is_mobile ? 0 : 1, borderColor: '#9d9b9b', paddingTop: 50}}>
                  <View style={{flexDirection: 'column', marginBottom: 20}}>
                    <View style={{flexDirection: 'row'}}><Text style={{width: 100, fontFamily: 'Comfortaa', fontSize: 18, marginBottom: 10, color: 'white', alignContent: 'center'}}>Where</Text><TouchableOpacity onPress={()=>{window.open('https://zoom.us/download')}}><Text style={{ fontFamily: 'Ysabeau', color: '#70dff5', fontSize: 22, marginBottom: 10, textDecorationLine: 'underline'}}>Zoom</Text></TouchableOpacity></View>
                    <View style={{flexDirection: 'row'}}>
                      <Text style={{width: 100}}></Text>
                      <Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 18, width: 300}}>Link provided 30 minutes prior to event. Make sure to download and install zoom before hand.</Text></View>
                  </View>
                  <View style={{flexDirection: 'column', marginBottom: 20}}>
                    <View style={{flexDirection: 'row'}}><Text style={{width: 100, fontFamily: 'Comfortaa', fontSize: 18, marginBottom: 10, color: 'white', alignContent: 'center'}}>When</Text><Text style={{ fontFamily: 'Ysabeau', fontSize: 18, marginBottom: 10, color: 'white'}}>{displayDate} {displayTime}</Text></View>
                    <View style={{flexDirection: 'row'}}><Text style={{width: 100}}></Text><TouchableOpacity onPress={addToCalendar}><Text style={[global_style.normal_link, { color: '#70dff5'}]}>Add to Calendar</Text></TouchableOpacity></View>
                  </View>
                  <View style={{flexDirection: 'column', marginBottom: 20}}>
                    <View style={{flexDirection: 'row'}}><View style={{width: 100}}><Text style={{ fontFamily: 'Comfortaa', fontSize: 18, color: 'white', alignContent: 'center'}}>What</Text></View><Text style={{fontFamily: 'Ysabeau', fontSize: 18, color: 'white'}}>A social, virtual dinner lead by a host where locals mingle over preparing a themed side dish.
                    </Text></View>
                  </View>
                  <View style={{flexDirection: 'column', marginBottom: 20}}>
                    <View style={{flexDirection: 'row'}}><View style={{width: 100}}><Text style={{ fontFamily: 'Comfortaa', fontSize: 18, color: 'white', alignContent: 'center'}}>Dish</Text></View><Text style={{fontFamily: 'Ysabeau', fontSize: 18, color: 'white'}}><TouchableOpacity onPress={()=>{window.open('https://www.thespruceeats.com/polish-potato-pancakes-recipe-1137080')}}><Text style={{fontFamily: 'Ysabeau', fontSize: 18, color: 'white'}}><Text style={{fontFamily: 'Ysabeau', color: '#e32652', textDecorationLine: 'underline'}}>{event.dish ? event.dish : 'TBD'}</Text></Text></TouchableOpacity>                  
                    </Text></View>
                  </View>
                  <br/>
                  <View style={{flexDirection: 'column'}}>
                    <View style={{flexDirection: 'row'}}><View style={{width: 100}}><Text style={{ fontFamily: 'Comfortaa', fontSize: 18, color: 'white', alignContent: 'center'}}>Who</Text></View><Text style={{fontFamily: 'Ysabeau', fontSize: 18, color: 'white'}}>Locals of All Age Ranges. RSVP limit <Text style={{fontSize: 18, fontFamily: 'Comfortaa', textDecorationLine: 'underline', color: 'white'}}>20 people</Text>.</Text></View>
                  </View>
                </View>
                {is_mobile ? pricing_panel : null}
                <Text style={[style.event_summary_location_time, { marginBottom: 50}]}>{description}</Text>
              </View>
              <View style={{flex: is_mobile ? 'unset' : 1, borderLeftWidth: is_mobile ? 0 : 1, borderColor: '#9d9b9b', marginLeft: is_mobile ? 0 : 50, paddingTop: is_mobile ? 0 : 30}}>
                {is_mobile ? null : pricing_panel}
                <Text style={[style.event_summary_location_time, {borderColor: '#9d9b9b', borderTopWidth: 1, borderColor: '#9d9b9b', paddingLeft: is_mobile ? 0 : 50, paddingTop: is_mobile ? 40 : 40, paddingBottom: 40}]}>{agenda}</Text>
                
              </View>
            </View>

            
        </View>
        <View style={{width: '100%'}}>
          <FAQ chosen_section={2} />
        </View>
      </View>
  }

  render() {

    let { mounted } = this.state;

    return (mounted ?
      <> <Modal ref={node=>this.modal_ref=node} putInParent={this.props.putInParent} />
      <View>{this.drawEvent()}</View></> : null
    );
  }

}

function Virtual(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <Event_Base {...props} navigate={navigate} />
  } else {
    return  <Event_Base {...props} navigate={props.navigation.push}  />
  }
}

export default Virtual
